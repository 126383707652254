
import {
  defineComponent, ref, onBeforeUnmount
} from 'vue';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import { useAuth } from '@/modules/auth-module';
import CreatorMenu from '../creators/CreatorMenu.vue';
import Notifications from '../creators/Notifications.vue';
import Gamification from '../commons/gamification/Gamification.vue';
import router from '@/router';


export default defineComponent({
  components: {
    Menu,
    CreatorMenu,
    Notifications,
    Gamification,
    Button
  },
  props:{
    shouldShowElement:{
      type:Boolean,
    },
  },
  setup(_, { emit }) {

    const { user } = useAuth();
    const userIsBrand = user?.value?.isBrandOwner();
    const isAdmin = user?.value?.isAdmin()
    const userIsCreator = user?.value?.isCreator();
    const scrollY = ref(true);
    const isSmallSidebar = ref(true);
    const navigation = document.getElementById('nav-bar') as HTMLInputElement | null;
    const userMenuItems = [
      {
        label: 'My Profile',
        icon: 'pi pi-fw pi-user',
        to: { name: 'my-profile' },
      },
      {
        label: 'Billing',
        icon: 'pi pi-fw pi-dollar',
        visible: userIsBrand,
      },
      {
        label: 'Payment Details',
        icon: 'pi pi-fw pi-money-bill',
        visible: userIsBrand,
      },
      // add logout at the end
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-fw pi-sign-out',
        to: { name: 'logout' },
      },
    ];

    const handleLeftBarChange = () => {
      isSmallSidebar.value = !isSmallSidebar.value;
    };

    const scrollListener = () => {
      if (window.scrollY > 20) {
        scrollY.value = false;
      } else {
        scrollY.value = true;
      }
    };
    window.addEventListener('scroll', scrollListener);
    window.addEventListener('isSmallSidebar', handleLeftBarChange, false);
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('isSmallSidebar', handleLeftBarChange);
    });

    const handleSwitch = () => {
      localStorage.setItem('new-design-toggle', 'deactivated');
      location.reload();
    }

    const isNewDesignEnabled = localStorage.getItem('new-design-toggle') === 'activated';

    const goToCreateCampaign = () => {
      router.push({ name: 'create-campaign' })
    }

    const isDashboard = ref(window.location.pathname === '/app/dashboard');
    const isWhiteListing = ref(window.location.pathname === '/app/admins/white-listing');
    const toggleMenu = (event: Event) => {
      emit('menu-toggle', event);
    };

    return {
      navigation,
      scrollY,
      user,
      userMenuItems,
      userIsBrand,
      userIsCreator,
      isSmallSidebar,
      isNewDesignEnabled,
      isDashboard,
      handleSwitch,
      goToCreateCampaign,
      isWhiteListing,
      toggleMenu,
      isAdmin
    };
  },
});
