import React, { useEffect, useState } from "react";

import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import { DataTable } from 'primereact/datatable';

import { CampaignPaymentMethod } from '@/data/types';

import { ExpandArrowDown, ExpandArrowUp } from '../img/Icons';
import { AndroidIcon, AppleIcon } from '@/react_app/img/Icons';

import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

import "./NewEarnings.css";
import { calculateCommission } from '@/utils/globals/numbers-utils';

const creatorTemplate = (data) => {
  return (
    <div className={'creatorTemplate'}>
      <Image src={data.photo} alt={'creator photo'} className={'creatorTemplatePhoto'}></Image>
      <p className={'creatorTemplateName'}>{data.name}</p>
    </div>
  )
};
const creatorEmailTemplate = (data) => (
  <div className="p-d-flex creatorTemplateName">
    <span className="value link" onClick={() => window.location = `mailto:${data?.email}`}>{data?.email || "-"}</span>
  </div>
);
const creatorTypeTemplate = (data) => (
  <div className="p-d-flex creatorTemplateName">
    <span className="value capital">{data?.userType || "-"}</span>
  </div>
);
const totalEarningsTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.totalEarnings?.toFixed(2) || 0}
    </div>
  )
};
const totalPaidTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.totalPaid?.toFixed(2) || 0}
    </div>
  )
};
const eligibleForPayoutTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      $ {data.amountEarnedThisCycle?.toFixed(2) || 0}
    </div>
  )
};
const gigaPayTemplate = (data) => {
  return (
    <div className={'defaultTemplate'}>
      {data.gigapayStatus ? 'Yes' : 'No'}
    </div>
  )
};
const campaignTemplate = (row) => {
  return <div className="p-d-flex creatorTemplateName">
    <Avatar className="p-overlay-badge  p-mr-2 photo" image={row?.campaignPhoto} size="xlarge"></Avatar>
    <span className="value">{row?.campaignName}</span>
  </div>
}
const campaignTypeTemplate = (row) => {
  return (
    <div className="p-d-flex creatorTemplateName">
      <span className="value">{row?.campaignPaymentMethod}</span>
    </div>
  )
}
const campaignPaymentAmountTemplate = (row) => (
  <>
    {row?.campaignPaymentMethod === CampaignPaymentMethod.CPC &&
      <span className='coverValue'>{row?.cpc.amount} {row?.cpc?.currency}</span>
    }
    {row?.campaignPaymentMethod === CampaignPaymentMethod.CPM &&
      <span className='coverValue'>{row?.cpm.amount} {row?.cpm?.currency}</span>
    }
    {row?.campaignPaymentMethod === CampaignPaymentMethod.CPI &&
      <>
        <div className='coverValue'>
          <AndroidIcon />
          {`${row?.cpi.android.amount} ${row?.cpi.android.currency}`}
        </div>
        <div className='coverValue'>
          <AppleIcon />
          {`${row?.cpi.ios.amount} ${row?.cpi.ios.currency}`}
        </div>
      </>
    }
  </>
);
const campaignCommissionRateTemplate = (row) => (
  <div className="defaultTemplate">
   <span className="value">{row?.appliedFee || 0} USD</span>
  </div>
);

const campaignEarnedTemplate = (row, isWithCommission) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="value">{isWithCommission ? row?.earned?.toFixed(2) : calculateCommission(row?.earned,row?.appliedFee)} USD</span>
    <div className="p-d-flex p-flex-column">
      <span>Android: {row?.earnedStats?.android || 0}</span>
      <span>IOS: {row?.earnedStats?.ios}</span>
      <span>Clicks: {row?.earnedStats?.clicks}</span>
    </div>
  </div>
);
const campaignPaidTemplate = (row, isWithCommission) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="value">{isWithCommission ? row?.paid?.toFixed(2) : calculateCommission(row?.paid,row?.appliedFee)} USD</span>
    <div className="p-d-flex p-flex-column">
      <span>Android: {row?.paidStats?.android || 0}</span>
      <span>IOS: {row?.paidStats?.ios || 0}</span>
      <span>Clicks: {row?.paidStats?.clicks || 0}</span>
    </div>
  </div>
);
const rowExpansionTemplate = (rowData) => {
  return (
    <DataTable value={rowData?.links} className="w-100">
      <Column field="campaignName" header="Campaign" body={campaignTemplate} style={{ width: '20%' }} />
      <Column field="campaignPaymentMethod" header="Payment Method"  style={{ width: '10%' }} body={campaignTypeTemplate} />
      <Column field="campaignPayment" header="Rate" body={campaignPaymentAmountTemplate} />   
      <Column field="appliedFee" header="Commission Rate" body={campaignCommissionRateTemplate} /> 
       <Column field="earned" header="Earned (With Commission)" body={(data) => campaignEarnedTemplate(data,true)} />   
      <Column field="earned" header="Earned (Without Commission)" body={(data) => campaignEarnedTemplate(data, false)} />
      <Column field="paid" header="Paid (With Commission)" body={(data) => campaignPaidTemplate(data,true)} />
      <Column field="paid" header="Paid  (Without Commission)" body={(data) => campaignPaidTemplate(data,false)} />
    </DataTable>
  )
}

export default function (props) {

  const { earnings, changePage, limit, loading } = props;

  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setNoResults(!(earnings?.creatorEarnings?.length))
  }, [earnings]);

  const expanderTemplate = (rowData) => {
    return (
      <div className='expanderTemplate'>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  }
  const onPageChange = (event) => {
    const { first } = event;
    setExpandedRows([])
    setFirst(first);
    changePage(first);
  };

  return (
    <div className={'earnings'}>
      <p className={'title'}>Earnings</p>
      <DataTable value={earnings?.creatorEarnings || []} emptyMessage="No earnings found" rowExpansionTemplate={rowExpansionTemplate}
        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
        loading={loading} loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} >
        <Column expander={true} body={expanderTemplate} />
        <Column field="name" header="Influencer" sortable body={creatorTemplate} style={{ width: '20%' }} />
        <Column field="userType" header="User Type" sortable body={creatorTypeTemplate} style={{ width: '20%' }} />
        <Column field="email" header="Email" body={creatorEmailTemplate} style={{ width: '20%' }}/>
        <Column field="totalEarnings" header="Total Earnings" sortable body={totalEarningsTemplate} />
        <Column field="totalPaid" header="Paid Out" sortable body={totalPaidTemplate} />
        <Column field="amountEarnedThisCycle" header="Eligible for Payout" sortable body={eligibleForPayoutTemplate}/>
        <Column field="gigapayStatus" header="On Gigapay" sortable body={gigaPayTemplate}/>
      </DataTable>
      {!noResults && <Paginator first={first} rows={limit} totalRecords={earnings?.maxCount || 0} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
    </div>
  )
}
