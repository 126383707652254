import React, { useEffect, useState } from 'react';
import router from '@/router';
import { Chart } from 'primereact/chart';
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import comingSoonImage from "../img/coming-soon-img.png";
import CampaignWhitelisting from "./CampaignWhitelisting";
import NewCreateCampaign from "@/react_app/NewCreateCampaign/NewCreateCampaign";
import { PaymentMethodDisplay, OtherUrlIcon, tabs, TabStep } from "@/react_app/NewCampaignStats/NewCampaignStatsAdditional";

import { CampaignPaymentMethod, CampaignStatus } from "@/data/types";
import { paymentMethodsOptions } from "@/pages/campaigns/create/data.campaign";
import {
  BackIcon, CheckMark, ClicksIcon, CommentsIcon, PencilIconRed,
  ViewsIcon, WhiteAppleIcon, WhiteAndroidIcon, LikesIcon
} from "@/react_app/img/Icons";
import {
  commentBodyTemplate, likesBodyTemplate, viewsBodyTemplate, dateBodyTemplate, statusBodyTemplate, videoBodyTemplate
} from "@/react_app/NewCampaignStats/NewCampaignStatsAdditional";
import AttachmentsDisplay from '@/react_app/Other/AttachmentsDisplay/AttachmentsDisplay';


import "./NewCampaignStats.css";
import "./NewCampaignStatsAdditional.css";
import 'primeicons/primeicons.css';
import { useAuth } from '@/modules';
import { getRegion } from '@/utils/campaign-utils';

const getUrlName = (link) => {
  if (link.type === "Google") {
    return 'Google Play Link';
  } else if (link.type === "Apple") {
    return 'Apple App Store Link';
  }
  return 'Product Website Link';
}

export default function (props) {
  const { user } = useAuth();
  const isAdmin = user.value.isAdmin()
  const isBrand = user.value.isBrandOwner();

  const { campaign, liveContentItems, campaignId, isLoadingPublishCampaign } = props;
  const [campaignManagementPreview, setCampaignManagementPreview] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [editCampaign, setEditCampaign] = useState(true);
  const [warningCloseModal, setWarningCloseModal] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalComments, setTotalComments] = useState(0);
  const [totalInstallsAndroid, setTotalInstallsAndroid] = useState(0);
  const [totalInstallsIOS, setTotalInstallsIOS] = useState(0);
  const [statisticsItemsNumber, setStatisticsItemsNumber] = useState(6);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { isAdminEdit, title } = router.currentRoute.value.query;
  const statisticsItems = [
    {
      title: 'Total Views',
      icon: <ViewsIcon />,
      value: totalViews,
    },
    {
      title: 'Total Clicks',
      icon: <ClicksIcon />,
      value: totalClicks,
    },
    {
      title: 'Total Likes',
      icon: <LikesIcon />,
      value: totalLikes,
    },
    {
      title: 'Total Comments',
      icon: <CommentsIcon />,
      value: totalComments,
    },
    {
      title: 'Total Installs Android',
      icon: <WhiteAndroidIcon />,
      value: totalInstallsAndroid,
    },
    {
      title: 'Total Installs iOS',
      icon: <WhiteAppleIcon />,
      value: totalInstallsIOS,
    },
  ];

  useEffect(() => {
    if (campaign) {
      setFilteredAttachments(campaign.attachments.filter(file => !!file.url));
      setTotalInstallsAndroid(campaign.totalCampaignInstalls.android);
      setTotalInstallsIOS(campaign.totalCampaignInstalls.ios);
      setTotalClicks(campaign.totalCampaignClicks);
      if (campaign.paymentMethod !== paymentMethodsOptions.CPI) {
        setStatisticsItemsNumber(4);
      }
      setCampaignManagementPreview(campaign?.photo);
    }

    if (isAdmin && isAdminEdit) {
      setEditCampaign(false);
    }

    const data = {
      labels: ['Amount Spent', 'Current Amount'],
      datasets: [
        {
          data: [campaign?.budget.amount - campaign?.budget.amountLeft, campaign?.budget.amountLeft],
          backgroundColor: ['#E73D38', '#130A23'],
          borderWidth: 0,
        },
      ],
    };

    const options = {
      cutout: '70%',
      plugins: {
        legend: {
          display: false,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);    
  }, [campaign]);

  useEffect(() => {
    if (liveContentItems) {
      liveContentItems.forEach((item) => {
        setTotalViews((prevTotalViews) => prevTotalViews + parseInt(item.statistics.viewCount));
        setTotalComments((prevTotalComments) => prevTotalComments + parseInt(item.statistics.commentCount));
        setTotalLikes((prevTotalLikes) => prevTotalLikes + parseInt(item.statistics.likeCount));
      });
    }
  }, [liveContentItems]);

  useEffect(() => {
    if (title && !isBrand) {
      setActiveTab(title === 'whitelisting' ? 1 : 0);
    }
  }, [title]);

  const handleCampaignNameEdit = (e) => {
    const { value } = e.target;
    setCampaignName(value);
  };
  const handleChangeTab = (currentTab) => {
    setActiveTab(currentTab);
  }

  return (
    <div className={`campaign-stats ${isAdmin ? 'admin-edit' : ''}`}>
      {!isAdmin && <div className={'navigationMenu'}>
        <div className={'goBack'} onClick={() => props.handleReturn()}>
          <BackIcon />
          <span className={'goBackText'}>Campaign Management</span>
        </div>
        <div className={'menuCampaignInfo'}>
          <Image src={campaignManagementPreview} alt={'campaign cover'} className={'menuCampaignCover'} />
          <p className={'menuCampaignName'}>{campaign?.name}</p>
        </div>
        <div className={'menuTabsWrapper'}>
          <div className={'menuTabs'}>
            {tabs.map((item, index) =>
              <div key={index} className={activeTab === index ? 'menuTabSelected' : 'menuTabNormal'} onClick={() => handleChangeTab(index)}>
                {activeTab === index ? item.iconSelected : item.iconNormal}
                <span className={'menuTabText'}>{item.title}</span>
              </div>)}
          </div>
        </div>
      </div>}
      {activeTab === TabStep.Details &&
        (editCampaign ?
          <div className={'campaignDetails'}>
            <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${campaign?.photo})` }}>
              <p className={'coverTitle'}>{campaign?.name}</p>
              <div className={'coverGrid'}>
                <div>
                  <p className={'coverLabel'}>Category:</p>
                  <p className={'coverValue'}>{campaign?.targetMarket.title}</p>
                </div>
                <div>
                  <p className={'coverLabel'}>Campaign {campaign?.paymentMethod}:</p>
                  <PaymentMethodDisplay campaign={campaign} />
                </div>
                {campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
                  <div>
                    <p className={'coverLabel'}>Max payout:</p>
                    <p className={'coverValue'}>{campaign?.cpm?.payoutCap} {campaign?.cpm?.currency}</p>
                  </div>
                }
                <div>
                  <p className={'coverLabel'}>Application time left:</p>
                  <p className={'coverValue'}>{campaign?.dates.endDate ? new Date(campaign.dates.endDate).toLocaleDateString
                    ('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Until budget is complete'}</p>
                </div>
                {campaign?.paymentMethod === CampaignPaymentMethod.CPM ? <div>
                  <p className={'coverLabel'}>Language:</p>
                  <p className={'coverValue'}>{campaign?.languages?.map(language => language?.name)?.join(',')}</p>
                </div> : <div>
                  <p className={'coverLabel'}>Region:</p>
                  <p className={'coverValue'}>{getRegion(props.campaign,user.value?.type)}</p>
                </div>}
              </div>
              <div className='actionWrapper'>
                {campaign?.status !== CampaignStatus.Completed &&
                  <div className='publish'>
                    <Button className={'closeButton'} onClick={() => setWarningCloseModal(true)}>Complete Campaign</Button>
                  </div>}
                <Button label="Edit Campaign" className={'editButtonTransparent'} onClick={() => setEditCampaign(false)}>
                  <PencilIconRed />
                </Button>
              </div>
            </div>
            <div className={'infoMain'}>
              <div className={'infoMainLeft'}>
                <div>
                  <p className={'infoLabel'}>Campaign Details</p>
                  <p className={'infoText'}>{campaign?.productName}</p>
                </div>
                <div>
                  <p className={'infoLabel'}>Talking points</p>
                  <div className={'talkingPointsWrapper'}>
                    {campaign?.talkingPoints?.filter(item => item !== '').map((item, index) =>
                      <div key={index} className={'talkingPoint'}>
                        <CheckMark />
                        <div className={'talkingPointText'}>
                          {item}
                        </div>
                      </div>)}
                  </div>
                </div>
              </div>
              <div className={'infoMainRight'}>
                <div>
                  <p className={'infoLabel'}>Campaign URls</p>
                  <div className={'linkWrapper'}>
                    {campaign?.productUrls?.slice(1).map((item, index) =>
                    <>
                      {item.value && 
                        <div key={index}>
                          <p className={'linkLabel'}>{getUrlName(item)}</p>
                          <div className={'link'}>
                            <OtherUrlIcon type={item.type} />
                            <span className={'overflowEllipsis'}>{item.value}</span>
                          </div>
                        </div>
                      }
                    </>)}
                  </div>
                </div>
                <div>
                  <p className={'infoLabel'}>Call to Action</p>
                  <div className={'callToActionBox'}>{campaign?.callToAction}</div>
                </div>
                <div>
                  <p className={'infoLabel'}>Attachments</p>
                  <AttachmentsDisplay attachments={filteredAttachments} />
                </div>
              </div>
            </div>
            <div className={'buttonGroup'}>
              <div className='publish'>
                {campaign?.status !== CampaignStatus.Completed && <Button className={'closeButton'} onClick={() => setWarningCloseModal(true)}>Complete Campaign</Button>}
              </div>
              <div className='edit'>
                <Button className={'closeButton'} onClick={() => setEditCampaign(false)}>Edit Campaign</Button>
                {props?.campaign?.status === "draft" &&
                  <Button className={'editButtonPrimary publishCampaignButton'} loading={isLoadingPublishCampaign} onClick={() => props.handlePublishCampaign(campaignId)}>Publish Campaign</Button>
                }
              </div>
            </div>
          </div> :
          <div className={'createCampaignWrapper'}>
            <NewCreateCampaign campaign={campaign} isCampaignManagement={true} cancelEdit={() => setEditCampaign(true)} saveEdit={props.saveEdit}
              setCampaignManagementPreview={setCampaignManagementPreview} />
          </div>

        )
      }
      {activeTab === TabStep.Whitelisting &&
        <CampaignWhitelisting campaignId={campaignId} campaign={campaign} />
      }
      {activeTab === TabStep.Statistics &&
        <div className={'statistics'}>
          <p className={'statisticsTitle'}>Statistics</p>
          <div className={'statisticsSectionsGroup'}>
            <div className={'statisticsSectionWrapper'}>
              <p className={'statisticsLabel'}>Overview</p>
              <div className={'statisticsOverview'}>
                {statisticsItems.slice(0, statisticsItemsNumber).map((item, index) =>
                  <div className={'statisticsOverviewItem'} key={index}>
                    <div className={'statisticsOverviewItemIcon'}>
                      {item.icon}
                    </div>
                    <div className={'statisticsOverviewItemText'}>
                      <p className={'statisticsOverviewItemTitle'}>{item.title}</p>
                      <p className={'statisticsOverviewItemValue'}>{item.value}</p>
                    </div>
                  </div>)}
              </div>
            </div>
            <div className={'statisticsSectionWrapper'}>
              <p className={'statisticsLabel'}>Budget</p>
              <div className={'statisticsBudgetWrapper'}>
                <div className={'statisticsChartWrapper'}>
                  <Chart type="doughnut" data={chartData} options={chartOptions} style={{ width: '140px', height: '140px' }} />
                  <div className={'statisticsChartText'}>
                    <p className={'statisticsChartTextLabel'}>Budget Left</p>
                    <p className={'statisticsChartTextNumber'}>$ {campaign?.budget.amountLeft}</p>
                    <p className={'statisticsChartTextPercentage'}>{campaign?.budget.amount !== 0 ? Math.ceil(((campaign?.budget.amount - campaign?.budget.amountLeft) / campaign?.budget.amount) * 100) : 0}%</p>
                  </div>
                </div>
                <div className={'exportReportWrapper'}>
                  <Button disabled={true} className={'exportReportButton'}>Export the report</Button>
                  <p className={'exportReportText'}>
                    Export your campaign’s statistics.<br />
                    Currently under construction.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className={'statisticsLabel'}>Timeline</p>
            <div className={'analyticsWrapper'}>
              <p className={'comingSoonTitle'}>Coming Soon</p>
              <p className={'comingSoonText'}>We will be adding the analytics for your campaigns very soon!</p>
              <Image src={comingSoonImage} alt={'coming soon'} className={'comingSoonImage'} />
            </div>
          </div>
        </div>
      }
      {activeTab === TabStep.LiveContent &&
        <div className={'liveContent'}>
          <p className={'liveContentTitle'}>Live Content</p>
          <DataTable value={liveContentItems} removableSort emptyMessage={"No live content found."} onRowClick={(e) => props.goToContent(e.data.id)}>
            <Column field={"title"} header={"Video"} body={videoBodyTemplate} sortable style={{ width: '40%' }}></Column>
            <Column field={"public"} header={"Status"} body={statusBodyTemplate} sortable style={{ width: '12%' }}></Column>
            <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '12%' }}></Column>
            <Column field={"statistics.viewCount"} header={"Views"} body={viewsBodyTemplate} sortable style={{ width: '12%' }}></Column>
            <Column field={"statistics.likeCount"} header={"Likes"} body={likesBodyTemplate} sortable style={{ width: '12%' }}></Column>
            <Column field={"statistics.commentCount"} header={"Comments"} body={commentBodyTemplate} sortable style={{ width: '12%' }}></Column>
          </DataTable>
        </div>
      }
      <Dialog visible={warningCloseModal} onHide={() => setWarningCloseModal(false)} className={'warningModal'}>
        <div className={'warningTopLayer'}>
          <i className="pi pi-times" onClick={() => setWarningCloseModal(false)} style={{ color: '#FFF', fontSize: '16px', cursor: 'pointer' }} />
        </div>
        <div className={'warningContent'}>
          <p className={'warningTitle'}>You are about to complete {campaign?.name}!</p>
          <p className={'warningText'}>Are you sure you want to complete this campaign?</p>
          <Button onClick={() => props.campaignClosing().then(() => setWarningCloseModal(false))} className={'confirmButton'}>Complete campaign</Button>
        </div>
      </Dialog>
    </div>
  )
}
