import React, { useEffect, useState } from 'react';
import "./NewCampaign.css";
import { CampaignPaymentMethod } from "@/data/types";
import {
  AndroidIcon,
  AppleIcon,
  AppStoreIconDefault,
  CheckMark,
  GooglePlayIconDefault,
  WebPageIconDefault,
  BackIcon, CopyIcon,
  PencilIconRed,
  RedX
} from "@/react_app/img/Icons";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { useAuth, useBrands, useCampaigns, useCommonApi } from "@/modules";
import { useContentItems } from "@/modules";
import NewContentListItem from "../NewLatestItems/NewContentListItem/NewContentListItem"
import { calculateCpiRate } from "@/utils/globals/cpi-rate";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { MAX_TALKING_POINTS, cancelOptions, chooseOptions, uploadOptions } from '../NewCreateCampaign/NewCreateCampaignAdditional';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import AttachmentsDisplay from '@/react_app/Other/AttachmentsDisplay/AttachmentsDisplay';
import { Dropdown } from 'primereact/dropdown';
import { getRegion } from '@/utils/campaign-utils';

export function OtherUrlIcon({ type }) {
  return (
    <div>
      {type === "Webpage" && <WebPageIconDefault />}
      {type === "Google" && <GooglePlayIconDefault />}
      {type === "Apple" && <AppStoreIconDefault />}
    </div>
  )
}

const getUrlName = (link) => {
  if (link.type === "Google") {
    return 'Google Play Link';
  } else if (link.type === "Apple") {
    return 'Apple App Store Link';
  }
  return 'Product Website Link';
}

const defaultEditOptions = {
  campaignEndDate: false,
  productName: false,
  talkingPoints: false,
  callToAction: false,
  attachments: false,
};

export default function (props) {
  const user = useAuth();
  const campaignModule = useCampaigns();
  const { manager: { getMasterData } } = useCommonApi();
  const [filteredAttachments, setFilteredAttachments] = useState([]);
  const [brand, setBrand] = useState('');
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [campaign, setCampaign] = useState({
    campaignEndDate: "",
    productName: '',
    talkingPoints: ['', ''],
    callToAction: '',
    attachments: [],
    languages: [],
  });
  const [languageList, setLanguageList] = useState([]);
  const [isEndDateExact, setIsEndDateExact] = useState(false);
  const [editOptions, setEditOptions] = useState(defaultEditOptions);
  const [isNewUpload, setIsNewUpload] = useState(false);
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const brands = useBrands();
  const contentItems = useContentItems();
  const isAdmin = props?.user?.isAdmin();
  const isEditMode = props?.campaign;

  useEffect(() => {
    if (props.campaign) {
      setFilteredAttachments(props.campaign.attachments.filter(file => !!file.url));
      brands.manager.loadBrand(props.campaign.brand.id).then((result) => {
        setBrand(result);
      });
      contentItems.list.getCampaignContent(props.campaign.id).then((result) => {
        setContent(result);
      });
      if (isAdmin) {
        setCampaign(({
          campaignEndDate: props.campaign.dates.endDate,
          productName: props.campaign.productName,
          talkingPoints: props.campaign.talkingPoints,
          callToAction: props.campaign.callToAction,
          attachments: props.campaign.attachments?.filter(file => !!file?.url),
          languages: props.campaign?.languages || [],
        }));
        if (props.campaign?.dates?.endDate) setIsEndDateExact(true);
      }
    }
    return () => {
      setIsNewUpload(false);
    }
  }, [props.campaign]);

  useEffect(() => {
    if (!!props) {
      setIsLoading(props?.loading)
    }
  }, [props?.loading])

  useEffect(() => {
    getMasterData('').then((res) => {
      if (res) {
        setLanguageList(res.data.languages.map(language => {
          return {
            value: language.name, name: language.name, id: language.id, code: language.code, isActive: language.isActive
          }
        }));
      } else {
        setLanguageList([]);
      }
    });
  }, []);


  const goToUrl = (url) => {
    window.open(url, '_blank').focus();
  }

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
  };

  const updateCampaignData = async (data) => {
    if (data?.attachments) {
      setIsLoading(true);
      const updatedProgressCampaign = {
        ...data,
      }
      updatedProgressCampaign.attachments = [...data.attachments];
      if (updatedProgressCampaign.attachments?.length) {
        const formData = new FormData();
        for await (const file of updatedProgressCampaign.attachments) {
          if (!file.url) {
            formData.set('files[]', file);
            await campaignModule.manager.attachments(props?.campaign?.id, formData).then(async (response) => {
              if (response && response.attachments.length > 0) {
                const latestUpload = response.attachments[response.attachments.length - 1];
                updatedProgressCampaign.attachments.push(latestUpload)
              }
            }).catch(error => {
              if (error) {
                console.log('attachments error', error);
                setIsLoading(false);
              }
            });
          }
        }
      }
      updatedProgressCampaign.attachments = updatedProgressCampaign.attachments.filter(attachment => attachment.url);
      await campaignModule.manager.update(props.campaign?.id || props.campaign?._id, updatedProgressCampaign).then(() => {
        props?.refreshData();
        setEditOptions(defaultEditOptions)
      }).catch(error => {
        if (error) {
          setIsLoading(false);
        }
      });
    } else {
      await campaignModule.manager.update(props.campaign?.id || props.campaign?._id, data).then(() => {
        props?.refreshData();
        setEditOptions(defaultEditOptions)
      });
    }
  };

  const handleTalkingPointChange = (e, index) => {
    setCampaign(prevState => ({
      ...prevState,
      talkingPoints: prevState.talkingPoints.map((item, i) => (i === index ? e.target.value : item)),
    }));
  };

  const handleRemoveTalkingPoint = (index) => {
    const newTalkingPoints = [...campaign.talkingPoints];
    newTalkingPoints.splice(index, 1);
    setCampaign((prevState) => ({ ...prevState, talkingPoints: newTalkingPoints }));
  };

  const onTemplateSelect = (e) => {
    if (!!isEditMode) {
      setIsNewUpload(true);
    }
    const { files } = e;

    const combinedAttachments = [...campaign.attachments, ...files];
    const seen = new Set();

    const filteredAttachments = combinedAttachments.filter(item => {
      const url = item.url || item.objectURL || item.name;
      if (!url || seen.has(url)) {
        return false;
      }
      seen.add(url);
      return true;
    });

    setCampaign(prevState => ({ ...prevState, attachments: filteredAttachments }));
  };

  const isSameFile = (attachment, file) => {
    return (attachment.url && attachment.url === file.url) || (attachment.objectURL && attachment.objectURL === file.objectURL);
  };


  const onTemplateRemove = (e) => {
    const { file } = e;
    setCampaign(prevState => ({
      ...prevState,
      attachments: prevState.attachments.filter(attachment => !isSameFile(attachment, file))
    }));
  };


  const FileUploadRow = ({ file, onDelete }) => {
    return (
      <div className="p-fileupload-row" data-pc-section="file">
        <img
          role="presentation"
          className="p-fileupload-file-thumbnail"
          src={file.url ?? file.objectURL}
          width="50"
          data-pc-section="thumbnail"
          alt={file.name}
        />
        <div data-pc-section="details">
          <div className="p-fileupload-filename" data-pc-section="filename">
            {file.name}
          </div>
          <span data-pc-section="filesize">{file.size}</span>
          <span
            className="p-fileupload-file-badge p-badge p-component p-badge-warning"
            data-pc-name="badge"
            data-pc-section="root"
          >
            Pending
          </span>
        </div>
        <div data-pc-section="actions">
          <button
            className="p-button p-component p-button-icon-only p-button-text p-button-rounded p-button-danger"
            type="button"
            data-pc-name="button"
            data-pc-section="root"
            onClick={() => onDelete(file)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="p-icon"
              aria-hidden="true"
            >
              <path
                /* eslint-disable-next-line max-len,vue/max-len */
                d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z"
                fill="currentColor"
              />
            </svg>
            <span className="p-button-label p-c">&nbsp;</span>
          </button>
        </div>
      </div>
    );
  };

  const FileUploadList = ({ files, onDelete, isNewUpload }) => {
    return (
      <div className={`uploaded-attachments-list ${isNewUpload ? 'show' : 'hide'}`}>
        {(!isEditMode && (files?.length < 1)) ? <p className={'uploadText'}>Drag and drop files to here to upload.</p>
          : files.map(file => (
            <FileUploadRow key={file.id} file={file} onDelete={onDelete} />
          ))
        }
      </div>
    );
  };

  const handleDeleteAttachment = (file) => {
    setCampaign(prevState => ({
      ...prevState,
      attachments: prevState.attachments.filter(attachment => !isSameFile(attachment, file))
    }));
  };

  const handleLanguages = (value) => {
    const selectedLanguage = languageList.find(language => language.value === value);
    setCampaign(prevState => ({ ...prevState, languages: [selectedLanguage] }));
  }

  return (
    <div className={'campaign-page'}>
      {isLoading ?
        <div className={'loading-wrap'}><span className="loader wrap"></span></div> :
        <div>
          <div className={'goBack'} onClick={() => props.handleBackToAll()}>
            <BackIcon />
            <span className={'goBackLabel'}>All Campaigns</span>
          </div>
          <div className={'campaignDetails'}>
            <div className={'coverBackground'} style={{ background: `linear-gradient(rgba(19, 10, 35, 0.7), rgba(19, 10, 35, 0.7)), url(${props.campaign?.photo})` }}>
              <p className={'coverTitle'}>{props.campaign?.name}</p>
              <div className={'coverGrid'}>
                <div>
                  <p className={'coverLabel'}>Category:</p>
                  <p className={'coverValue'}>{props.campaign?.targetMarket.title}</p>
                </div>
                <div>
                  <p className={'coverLabel'}>Campaign {props.campaign?.paymentMethod}:</p>
                  {props.campaign?.paymentMethod === CampaignPaymentMethod.CPC &&
                    <p className={'coverValue'}>{calculateCpiRate(props.campaign?.cpc.amount, props.campaign?.appliedFee)} {props.campaign?.cpc?.currency}</p>
                  }
                  {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
                    <p className={'coverValue'}>{calculateCpiRate(props.campaign?.cpm.amount, props.campaign?.appliedFee)} {props.campaign?.cpm?.currency}</p>
                  }
                  {props.campaign?.paymentMethod === CampaignPaymentMethod.CPI &&
                    <div>
                      <div className={'coverValue'}>
                        <AndroidIcon />
                        {calculateCpiRate(props.campaign?.cpi.android.amount, props.campaign?.appliedFee)}{' '}
                        {props.campaign?.cpi.android.currency}
                      </div>
                      <div className={'coverValue'}>
                        <AppleIcon />
                        {calculateCpiRate(props.campaign?.cpi.ios.amount, props.campaign?.appliedFee)}{' '}
                        {props.campaign?.cpi.ios.currency}
                      </div>
                    </div>
                  }
                </div>
                {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
                  <div>
                    <p className={'coverLabel'}>Max payout:</p>
                    <p className={'coverValue'}>{props.campaign?.cpm?.payoutCap} {props.campaign?.cpm?.currency}</p>
                  </div>
                }
                <div>
                  <p className={`inlineEdit coverLabel`}>Application time left:
                    {isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, campaignEndDate: !prevState.campaignEndDate }))}>
                      <PencilIconRed />
                    </Button>}
                  </p>
                  {
                    (editOptions.campaignEndDate && isAdmin) ?
                      <div className={'endDateField  edit-option-wrap'}>
                        <Calendar className={'calendarField'} value={campaign.campaignEndDate ? new Date(campaign.campaignEndDate) : ""} onChange={(e) => {
                          setCampaign(prevState =>
                            ({ ...prevState, campaignEndDate: e.value })); setIsEndDateExact(true)
                        }} minDate={endDate} dateFormat="dd/mm/yy" />
                        <span className={'endDateLabel'}>Or</span>
                        <div className={isEndDateExact ? 'endDateOtherOption' : 'endDateOtherOptionSelected'}
                          onClick={() => { setIsEndDateExact(false); setCampaign(prevState => ({ ...prevState, campaignEndDate: "" })) }}>
                          <i className="pi pi-check" style={{ color: '#FFF', fontSize: '20px', marginRight: '10px' }} />
                          Until Budget is Complete
                        </div>
                        <Button Name="p-button-success date" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ dates: { startDate: new Date(), endDate: campaign.campaignEndDate } })} />
                      </div> :
                      <p className={'coverValue'}>{props.campaign?.dates.endDate ? new Date(props.campaign.dates.endDate).toLocaleDateString
                        ('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Until budget is complete'}</p>
                  }
                </div>
                {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM ? <div>
                  <p className={'coverLabel'}>Language:{isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, language: !prevState.language }))}>
                    <PencilIconRed />
                  </Button>}</p>
                  <div className='p-d-flex p-align-center'>
                    {(editOptions.language && isAdmin) ?
                      <>
                        <Dropdown className='dropdownField' value={campaign?.languages[0]?.name} onChange={(e) => {
                          handleLanguages(e.value);
                        }}
                          options={languageList} optionLabel="name" placeholder="Select language" />
                        <Button className="p-button-success" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ languages: campaign.languages.map(language => language.id).join("") || "" })} />
                      </>
                      :
                      <p className={'coverValue'}>{props?.campaign?.languages?.map(language => language?.name)?.join(',')}</p>
                    }
                  </div>
                </div> : <div>
                  <p className={'coverLabel'}>Region:</p>
                  <p className={'coverValue'}>{getRegion(props.campaign,user.user?.value?.type)}</p>
                </div>}
              </div>
              <div className={'coverStatus'}>
                {props.campaign?.status}
              </div>
            </div>
            <div className={'infoMain'}>
              <div className={'infoMainLeft'}>
                <div>
                  <p className={'infoLabel'}>Campaign Details
                    {isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, productName: !prevState.productName }))}>
                      <PencilIconRed />
                    </Button>}
                  </p>
                  {
                    (editOptions.productName && isAdmin) ?
                      <div className='edit-option-wrap'>
                        <InputTextarea autoResize value={campaign.productName} onChange={(e) => setCampaign(prevState => ({ ...prevState, productName: e.target.value }))}
                          rows={5} className={'textArea'} placeholder={'Tell creators about your campaign'} />
                        <Button className="p-button-success" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ productName: campaign.productName })} />
                      </div> :
                      <p className={'infoText'}>{props.campaign?.productName}</p>
                  }
                </div>
                <div>
                  <p className={'infoLabel'}>Talking points
                    {isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, talkingPoints: !prevState.talkingPoints }))}>
                      <PencilIconRed />
                    </Button>}
                  </p>
                  {(editOptions.talkingPoints && isAdmin) ?
                    <div className={'talkingPointsWrapper edit-option-wrap'}>
                      {campaign?.talkingPoints?.map((item, index) =>
                        <div key={index}>
                          <p className={'talkingPointLabel'}>Talking point {index + 1}</p>
                          <InputText placeholder='Enter your desired talking point' className={'talkingPointField'} value={item}
                            onChange={(e) => handleTalkingPointChange(e, index)} />
                          {<RedX onClick={() => handleRemoveTalkingPoint(index)} />}
                        </div>)}
                      {campaign?.talkingPoints.length <= MAX_TALKING_POINTS && <p className={'addField'}
                        onClick={() => setCampaign((prevState) => ({ ...prevState, talkingPoints: [...prevState.talkingPoints, ''] }))}>+ Add another Talking Point</p>}
                      <Button className="p-button-success" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ talkingPoints: campaign.talkingPoints })} />
                    </div> :
                    <div className={'talkingPointsWrapper'}>{props.campaign?.talkingPoints?.filter(item => item !== '').map((item, index) =>
                      <div key={index} className={'talkingPoint'}>
                        <CheckMark />
                        <div className={'talkingPointText'}>
                          {item}
                        </div>
                      </div>)}
                    </div>
                  }
                </div>
              </div>
              <div className={'infoMainRight'}>
                <div>
                  <p className={'infoLabel'}>Campaign URls</p>
                  <div className={'linkWrapper'}>
                    {props.campaign?.productUrls?.map((item, index) =>
                    <>
                      {item.value && <div key={index}>
                        <p className={'linkLabel'}>{getUrlName(item)}</p>
                        <div className={'link'}>
                          <OtherUrlIcon type={item.type} />
                          <span className={'overflowEllipsis'} onClick={() => goToUrl(item.value)}>{item.value}</span>
                          {item.value && <CopyIcon onClick={() => copyUrl(item.value)} />}
                        </div>
                      </div>}
                      </>)}
                  </div>
                </div>
                <div>
                  <p className={'infoLabel'}>Call to Action
                    {isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, callToAction: !prevState.callToAction }))}>
                      <PencilIconRed />
                    </Button>}
                  </p>
                  {
                    (editOptions.callToAction && isAdmin) ?
                      <div className='edit-option-wrap'>
                        <InputTextarea autoResize value={campaign.callToAction} onChange={(e) => setCampaign(prevState => ({ ...prevState, callToAction: e.target.value }))}
                          rows={5} className={'textArea'} placeholder={'Tell creators about your campaign'} />
                        <Button className="p-button-success" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ callToAction: campaign.callToAction })} />
                      </div> : <div className={'callToActionBox'}>{props.campaign?.callToAction}</div>
                  }
                </div>
                <div>
                  <p className={'infoLabel'}>Attachments
                    {isAdmin && <Button className={'editButtonTransparent inlineEdit'} onClick={(e) => setEditOptions(prevState => ({ ...defaultEditOptions, attachments: !prevState.attachments }))}>
                      <PencilIconRed />
                    </Button>}
                  </p>
                  {
                    (editOptions.attachments && isAdmin) ?
                      <div className={`file-upload-wrap edit-option-wrap ${!isEditMode ? 'new-upload create' : ''} ${isNewUpload ? 'new-upload' : ''}`}>
                        <FileUpload className={'fileUpload'} multiple emptyTemplate={
                          <>
                            {!!isEditMode && ((campaign?.attachments?.length > 0) ?
                              <FileUploadList key="one" files={campaign.attachments} onDelete={handleDeleteAttachment} isNewUpload={false} /> :
                              <p className={'uploadText'}>Drag and drop files to here to upload.</p>)
                            }
                          </>
                        } onRemove={onTemplateRemove}
                          chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} onSelect={onTemplateSelect} chooseLabel="Upload"
                          onClear={() => setIsNewUpload(false)}
                          {...(isEditMode ? {} : { itemTemplate: () => null })}
                        />
                        {isNewUpload && !!isEditMode && <FileUploadList files={[...campaign?.attachments.filter(attachment => !!isEditMode ? !!attachment.url : !!attachment.objectUrl)]} onDelete={handleDeleteAttachment} isNewUpload={true} />}
                        {!isEditMode && <FileUploadList key="two" files={[...campaign?.attachments.filter(attachment => !!attachment)]} onDelete={handleDeleteAttachment} isNewUpload={true} />}
                        <Button className="p-button-success" icon="pi pi-check" aria-label="Submit" onClick={() => updateCampaignData({ attachments: campaign.attachments })} />
                      </div> :
                      <AttachmentsDisplay attachments={filteredAttachments} />
                  }
                </div>
              </div>
            </div>
          </div>
          <Divider className={'divider'} />
          <div className={'overviewBottom'}>
            <div className={'overviewBottomLeft'}>
              <p className={'overviewLabel'}>About Brand</p>
              <div className={'brandPhotoRow'}>
                <Image src={brand?.photo} alt={'brand photo'} className={'brandPhoto'} />
                <span>{brand?.name}</span>
              </div>
              <p className={'overviewText'}>{brand?.description}</p>
            </div>
            <div className={'overviewBottomRight'}>
              <div className={'contentItemsTopRow'}>
                <p className={'overviewLabel'}>Content</p>
                <span className={'seeAll'} onClick={() => props.handleRoutingAll()}>See All</span>
              </div>
              <div className={'contentItemsWrapper'}>
                {content.slice(0, 4).map((item, index) =>
                  <div key={index} className={'contentItem'}>
                    <NewContentListItem item={item} creator={props.user} handleRouting={() => props.handleContentRouting(item.id)} />
                  </div>)}
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}
