import React, { useEffect, useState } from 'react';

import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';

import { CampaignStatus } from "@/data/types";
import { formatDate } from '@/utils/globals/date-utils';
import { compactNumber } from '@/utils/globals/curency-utils';

import { useCampaigns, useToastModule } from '@/modules';
import CustomLoader from '../Other/CustomLoader/CustomLoader';
import { CopyIconMenu, FieldTip, NewCampaignIcon } from "@/react_app/img/Icons";

import './NewCampaignsManagement.css'

const campaignManagementTabs = [
  { value: 'All', type: 'all' },
  { value: 'Active', type: 'active' },
  { value: 'Drafts', type: 'draft' },
  { value: 'Completed', type: 'completed' },
  { value: 'Pending', type: 'pending' },
];

const campaignBodyTemplate = (rowData) => {
  return (
    <div className={'campaignTemplate'}>
      <Image src={rowData.photo} alt={'campaign cover'} className={'campaignTemplateImage'} />
      <div className={'campaignTemplateText'}>
        <p className={'campaignTemplateTitle'}>{rowData.name}</p>
        <p className={'campaignTemplateDescription'}>{rowData.productName}</p>
      </div>
    </div>
  );
};

const statusBodyTemplate = (rowData) => {
  return (
    <div className={'statusTemplate'}>
      {rowData.status === CampaignStatus.Active &&
        <div className={'statusTemplateBox statusActive'} >
          Active
        </div>
      }
      {rowData.status === CampaignStatus.Draft &&
        <div className={'statusTemplateBox statusDraft'} >
          Draft
        </div>
      }
      {rowData.status === CampaignStatus.Completed &&
        <div className={'statusTemplateBox statusCompleted'} >
          Completed
        </div>
      }
      {rowData.status === 'pending' &&
        <div className={'statusTemplateBox statusDraft'} >
          Pending
        </div>
      }
    </div>
  );
};

const marketBodyTemplate = (rowData) => {
  return (
    <div className={'marketTemplate'}>
      {rowData.targetMarket.title}
    </div>
  );
};

const dateBodyTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      {rowData.dates.endDate ?
        formatDate(rowData.dates.endDate) :
        '—'
      }
    </div>
  );
};

const priceBodyTemplate = (rowData) => {
  return (
    <div className={'priceTemplate'}>
      {compactNumber(rowData.budget.amount)}{' '}{rowData.budget.currency}
    </div>
  );
};

export default function (props) {

  const {
    campaigns, pageChange, searchCampaigns, totalRecords, limit,
    changeStatus, isLoading, handleRoute, pushDraft, perspective, counters
  } = props;
  const toast = useToastModule();
  const page = useCampaigns(perspective);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [campaign, setCampaign] = useState();
  const [activeTab, setActiveTab] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [first, setFirst] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [time, setTime] = useState("");
  const [campaignName, setCampaignName] = useState('');
  const [currentCampaignName, setCurrentCampaignName] = useState('');
  const [warningCopyModal, setWarningCopyModal] = useState(false);
  const isDisable = currentCampaignName === campaignName;

  useEffect(() => {
    setNoResults(!(campaigns?.length))
  }, [campaigns]);

  const handleSearch = (e) => {
    const search = e.target.value?.trim();
    setSearchValue(search);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setFirst(0);
        searchCampaigns(search);
      }, 1000)
    );
  }

  const onPageChange = (event) => {
    setFirst(event.first);
    pageChange(event.first)
  };

  const handleChangeTab = (status) => {
    changeStatus(status);
    setActiveTab(status)
    setSearchValue('');
  }

  useEffect(() => {
    if (campaign) {
      setCurrentCampaignName(campaign?.name);
      setCampaignName(campaign?.name);
    }
  }, [campaign]);

  const actionTemplate = (rowData) => {
    return (
      <div className='action-wrap'>
        <Button className='button copy-campaign' tooltip='Copy Campaign' icon={CopyIconMenu}
          onClick={() => {
            setCampaign(rowData);
            setWarningCopyModal(true);
          }
          } >
          Copy Campaign
        </Button>
      </div>
    )
  };

  const createDraft = async (pushRouter) => {
    const updatedCampaign = { ...campaign };
    if (campaignName) {
      updatedCampaign.name = campaignName;
    }
    updatedCampaign.languages = updatedCampaign?.languages?.join(",") || ""
    updatedCampaign.categories = updatedCampaign?.categories?.join(",") || "";
    updatedCampaign.platforms = updatedCampaign?.platforms?.join(",") || "";

    const createdDraft = await page.manager.create(updatedCampaign);
    if (campaign.attachments?.length) {
      const formData = new FormData();
      for await (const file of campaign.attachments) {
        formData.set('files[]', file);
        await page.manager.attachments(createdDraft?.id, formData);
      }
    }
    if (pushRouter) {
      await pushDraft(createdDraft?.id);
    }
    return createdDraft;
  };

  const copyCampaign = async () => {
    setIsLoadingAction(true);
    await createDraft(true).then(async (createdCampaign) => {
      if (createdCampaign?.id) {
        await page.manager.pending(createdCampaign?.id);
        setIsLoadingAction(false);
      }
    }).catch(error => {
      if (error) {
        toast.showError(error.message || "Something went wrong, please try again!");
        setIsLoadingAction(false);
      }
    });
  };

  const handleCampaignNameEdit = (e) => {
    const { value } = e.target;
    setCampaignName(value);
  };

  return (
    <div className={'campaigns-management'}>
      <p className={'pageTitle'}>Campaign Management</p>
      <div className={'topLayer'}>
        <div className={'newCampaignSquare'}>
          <NewCampaignIcon />
          <p className={'newCampaignText'}>Create a new campaign and promote your game!</p>
          <Button className={'newCampaignButton'} onClick={() => handleRoute('create-campaign')}>New campaign</Button>
        </div>
        <div className={'newCampaignSquareMobile'}>
          <Button className={'newCampaignButton'} onClick={() => handleRoute('create-campaign')}>New campaign</Button>
        </div>
        <div className={'topLayerLeft'}>
          <div className={'statsWrapper'}>
            <div className={'statsSquare'}>
              <p className={'statsNumber'}>{counters?.activeCampaigns}</p>
              <p className={'statsText'}>Active Campaigns</p>
            </div>
            <div className={'statsSquare'}>
              <p className={'statsNumber'}>{counters?.totalApplications}</p>
              <p className={'statsText'}>Influencer Applications</p>
            </div>
            <div className={'statsSquare'}>
              <p className={'statsNumber'}>{counters?.submissions}</p>
              <p className={'statsText'}>Submissions</p>
            </div>
          </div>          
        </div>
      </div>
      <div>
        <div className={'tabsWrapper'}>
          <div className={'tabs'}>
            {campaignManagementTabs.map((item) =>
              <div key={item.type} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'} onClick={() => handleChangeTab(item.type)}>
                {item.value}
              </div>)}
          </div>
        </div>
        <div className={'campaignsSearchBarWrapper'}>
          <i className="pi pi-search" style={{ position: 'absolute', left: '11px', top: '10px' }} />
          <InputText className={'campaignsSearchBar'} placeholder='Search for campaign' value={searchValue} onChange={(e) => handleSearch(e)} />
        </div>
      </div>
      <DataTable value={campaigns} removableSort emptyMessage={"No campaigns found."} onRowClick={(e) => props.goToCampaign(e.data.id)}
        loadingIcon={
          <CustomLoader />
        }
        loading={isLoading}>
        <Column field={"name"} header={"Campaign"} body={campaignBodyTemplate} style={{ width: '40%' }}></Column>
        <Column field={"status"} header={"Status"} body={statusBodyTemplate} style={{ width: '15%' }}></Column>
        <Column field={"targetMarket.title"} header={"Market"} body={marketBodyTemplate} style={{ width: '15%' }}></Column>
        <Column field={"dates.endDate"} header={"Time Left"} body={dateBodyTemplate} sortable style={{ width: '14%' }}></Column>
        <Column field={"budget.amount"} header={"Price"} body={priceBodyTemplate} sortable style={{ width: '14%' }}></Column>
        <Column field={"budget.amount"} header={"Action"} body={actionTemplate} style={{ width: '17%' }}></Column>
      </DataTable>
      {!noResults && campaigns && campaigns?.length > 0 && <Paginator first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
      <Dialog visible={warningCopyModal} onHide={() => {
        setWarningCopyModal(false);
        setCampaign({});
      }} className={'warningModal'}>
        <div className={'warningTopLayer'}>
          <i className="pi pi-times" onClick={() => setWarningCopyModal(false)} style={{ color: '#FFF', fontSize: '16px', cursor: 'pointer' }} />
        </div>
        <div className={'warningContent'}>
          <p className={'warningTitle'}>You are about to copy {campaign?.name}!</p>
          <p className={'warningText'}>
            Copying a campaign will replicate the structure and settings, but it is crucial to note that certain data may
            become outdated or irrelevant. To ensure the success of your duplicated campaign, we strongly recommend that you
            carefully review and update all relevant information including campaign description, talking points, imagery, budget, etc.
          </p>
          <div className='input-wrap'>
            <p className={'Label'}>Campaign Name : <FieldTip title='Campaign name should be different from the current one.' /></p>
            <InputText className='input-field' autoFocus placeholder='Enter campaign name' value={campaignName} onChange={(e) => handleCampaignNameEdit(e)} disabled={!campaignName} />
          </div>
          <Button onClick={() => copyCampaign().then(() => setWarningCopyModal(false))} className={'confirmButton'} disabled={isDisable || isLoadingAction} loading={isLoadingAction}>Copy campaign</Button>
        </div>
      </Dialog>
    </div>
  )
}
