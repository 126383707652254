import React from 'react'

import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { formatDate } from '@/utils/globals/date-utils';

import "./NewCreatorAccountAdditional.css";

const numberBodyTemplate = (rowData) => (
    <div className='numberTemplate'>
        {rowData.id}
    </div>
);
const statusBodyTemplate = ({ status }) => {
    const statusClasses = {
        paid: 'paid',
        pending: 'pending',
        active: 'active',
        completed: 'complete'
    };

    return (
        <div className="statusTemplate">
            <div className={`statusTemplateBox ${statusClasses[status]}`}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
            </div>
        </div>
    );
};

const dateBodyTemplate = (rowData) => (
    <div className='dateTemplate'>
        {formatDate(rowData.dates.startDate)}
    </div>
);

const brandBodyTemplate = (rowData) => (
    <div className='brandTemplate'>
        <Image src={rowData.brand.photo} alt='brand photo' className='brandTemplatePhoto' />
        <p className='brandTemplateName'>{rowData.brand.name}</p>
    </div>
);
const campaignBodyTemplate = (rowData) => (
    <div className={'campaignTemplate'}>
        {rowData.name}
    </div>
);
const amountBodyTemplate = () => (
    <div className={'amountTemplate'}>
        $ 0
    </div>
);



export default function NewPaymentInvoice(props) {

    const { contracts } = props;

    return (
        <div className="paymentsWrapper">
            <DataTable value={contracts} removableSort emptyMessage="No results found.">
                <Column field="id" header="Payment Number" body={numberBodyTemplate} style={{ width: "26%" }} />
                <Column field="status" header="Status" body={statusBodyTemplate} sortable style={{ width: "14%" }} />
                <Column field="dates.startDate" header="Date" body={dateBodyTemplate} sortable style={{ width: "14%" }} />
                <Column field="brand.name" header="Brand" body={brandBodyTemplate} sortable style={{ width: "14%" }} />
                <Column field="name" header="Campaign Name" body={campaignBodyTemplate} sortable style={{ width: "18%" }} />
                <Column field="" header="Amount" body={amountBodyTemplate} style={{ width: "14%" }} />
            </DataTable>
        </div>
    )
}