import { BrandData } from './brand-types';
import { TrackingLink, UserData } from './user-types';
import { NegotiationOffer } from './negotiation-types';
import { AgencyWorkerChannel } from './agency-types';
import { CampaignApplicationStatusValues } from '../enums/campaign-application';

export enum CampaignStatus {
  Draft = 'draft',
  Active = 'active',
  Live = 'live',
  Completed = 'completed',
}

export enum CampaignPaymentMethod {
  CPC = 'CPC',
  CPI = 'CPI',
  CPM = 'CPM',
  CPL = 'CPL',
  CPA = 'CPA',
}

export interface CampaignPlatform {
  platform: string;
  followers: Array<object>;
}

export interface CampaignRights {
  platforms: string;
  duration: string;
  regions: Array<string>;
}
export interface BrandStatistics {
  activeCampaigns: number;
  submissions: number;
  totalApplications: number;
}
export interface CampaignList {
  campaigns: CampaignData[],
  pagination: number,
  statistics:BrandStatistics
}

export interface CampaignData {
  id: string;
  name: string;
  trackingLinks: Array<TrackingLink>;
  dates?: {
    startDate: string;
    endDate: string;
  };
  desiredRegions: Array<{
    name: string;
    value: string;
  }>;
  agreeTerms: string[];
  totalCampaignClicks: number;
  gamekeys: string;

  targetMarket: {
    type: string;
    title: string;
  };
  walletId: string;
  goal: string;
  creatorRequirements: {
    gender: Array<string>;
    audience: [{
      minAge: number;
      maxAge: number;
    }];
    country: Array<string>;
    language: string;
  };
  platforms: Array<CampaignPlatform>;
  period: Array<object>;
  attachments: Array<object>;
  contentType: string;
  brief: string;
  reworksCount: number;

  budget: {
    amount: number;
    amountLeft: number;
    currency: string;
  };
  rights: CampaignRights;

  photo: string;
  paymentInformation: {
    PersonType: string;
    FirstName: string;
    LastName: string;
    Birthday: number;
    Nationality: string;
    CountryOfResidence: string;
    LegalPersonType: string;
    Name: string;
    LegalRepresentativeAddress: object;
  };
  cpi?: {
    android: {
      amount: number;
      currency: string;
    };
    ios: {
      amount: number;
      currency: string;
    };
  };
  cpc?: {
    amount: number;
    currency: string;
  };
  cpm?: {
    amount: number;
    currency: string;
    payoutCap: number
  };
  paymentMethod: string;

  invitations?: Array<CampaignInvitationData>;
  applications?: Array<CampaignApplicationData>;
  contracts?: Array<CampaignContractData>;

  brand: string | BrandData;
  // creator
  status: CampaignStatus;
  appliedFee: number;
}

/// INVITATIONS
export interface CampaignInvitationData {
  id: string;
  creator: UserData;
  status: string;
  createdAt: string;
}

/// APPLICATIONS
export interface CampaignApplicationData {
  id: string;
  applicationId?: string;
  creator: UserData;
  status: CampaignApplicationStatus;
  createdAt: string;
  updatedAt?: string;
  offers: Array<NegotiationOffer>;
  draftUploaded?: boolean;
  channel?: string;
  paymentCapProposals?: CampaignApplicationPayoutCapProposal[];
}

export type CampaignApplicationStatus = keyof typeof CampaignApplicationStatusValues;

/// CONTRACTS
export interface CampaignContractData {
  id: string;
  creator: UserData;
  status: string;
  price: number;
  createdAt?: string;
  updatedAt?: string;
  channel?: string;
  channelId?: string;
  deliverables: Array<CampaignDeliverableData>;
}

export interface CampaignDeliverableComment {
  id: string;
  from: string; // creator/brand
  text: string;
}

export interface CampaignDeliverableLink {
  id: string;
  status: string;
  link: string;
  comments?: Array<CampaignDeliverableComment>;
}

export interface CampaignDeliverableData {
  id: string;
  status: string;
  links?: Array<CampaignDeliverableLink>;
  createdAt?: string;
  updatedAt?: string;
  type?: 'image' | 'video';
}

export interface CampaignsEarningsExpenses {
  id: string;
  totalAmount: number;
  withoutDeductedCommision: number;
}

export interface CampaignApplicationPayoutCapProposal {
  clientDecision: "pending" | "approved" | "rejected";
  proposedPayoutCap: number;
  forChannelId?: string;
  channelTitle?: string;
}

// Interfaces used to conform component-specific use cases
export interface ApplicationRef {
  applicationId: string,
  applicationStatus: string,
  capProposals?: CampaignApplicationPayoutCapProposal[],
}

export interface CreatorCAPRef {
  CAP: number,
}

export interface CreatorAgencyCAPRef {
  [key: string]: {
    data: CreatorCAPRef | null;
    isLoading: boolean;
  };
}

export interface AgencyApplicationRef extends ApplicationRef {
  applicationChannel: string,
}
