import React from "react";

import { Image } from "primereact/image";

import { CampaignPaymentMethod } from "@/data/types";
import { calculateCpiRate } from "@/utils/globals/cpi-rate";
import {
  AndroidIcon, AppleIcon, AppStoreIconDefault, CopyIcon, GooglePlayIconDefault,
  PencilIconGrey, PencilIconRed, PlayButtonGrey, PlayButtonRed, StatsIconGrey,
  StatsIconRed, WebPageIconDefault, WhitelistingGreyIcon, WhitelistingRedIcon
} from "@/react_app/img/Icons";

export const tabs = [
  {
    iconSelected: <PencilIconRed />,
    iconNormal: <PencilIconGrey />,
    title: 'Details',
  },
  {
    iconSelected: <WhitelistingRedIcon />,
    iconNormal: <WhitelistingGreyIcon />,
    title: 'Whitelisting',
  },
];

export const TabStep = {
  Details: 0,
  Whitelisting: 1,
  Statistics: 2,
  LiveContent: 3,
}

export function OtherUrlIcon({type}) {
  return (
    <div>
      {type === "Webpage" && <WebPageIconDefault />}
      {type === "Google" && <GooglePlayIconDefault />}
      {type === "Apple" && <AppStoreIconDefault />}
    </div>
  )
}

export function PaymentMethodDisplay(props) {
  return (
    <div>
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPC &&
        <p className={'coverValue'}>{calculateCpiRate(props.campaign?.cpc.amount, props.campaign?.appliedFee)} {props.campaign?.cpc?.currency}</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPM &&
        <p className={'coverValue'}>{calculateCpiRate(props.campaign?.cpm.amount, props.campaign?.appliedFee)} {props.campaign?.cpm?.currency}</p>
      }
      {props.campaign?.paymentMethod === CampaignPaymentMethod.CPI &&
        <div>
          <div className={'coverValue'}>
            <AndroidIcon/>
            {calculateCpiRate(props.campaign?.cpi.android.amount, props.campaign?.appliedFee)}{' '}
            {props.campaign?.cpi.android.currency}
          </div>
          <div className={'coverValue'}>
            <AppleIcon/>
            {calculateCpiRate(props.campaign?.cpi.ios.amount, props.campaign?.appliedFee)}{' '}
            {props.campaign?.cpi.ios.currency}
          </div>
        </div>
      }
    </div>
  )
}

export const videoBodyTemplate = (rowData) => {
  return (
    <div className={'videoTemplate'}>
      <Image src={rowData.previewPhoto} alt={'video thumbnail'} className={'videoTemplateImage'}/>
      <div className={'videoTemplateText'}>
        <p className={'videoTemplateTitle'}>{rowData.title}</p>
        <div className={'videoTemplateLink'}><span>{rowData.url}</span> <CopyIcon onClick={() => navigator.clipboard.writeText(rowData.url)}/></div>
        {rowData.public && <div className={'requestLicense'}>Request License</div>}
      </div>
    </div>
  );
};

export const statusBodyTemplate = (rowData) => {
  return (
    <div className={rowData.public ? 'statusTemplatePublic' : 'statusTemplateDraft'}>
      {rowData.public ? 'Public' : 'Draft'}
    </div>
  );
};

export const dateBodyTemplate = (rowData) => {
  return (
    <div className={'dateTemplate'}>
      <p className={'dateValue'}>{new Date(rowData.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      <p className={'dateText'}>{rowData.public ? 'Published' : 'Uploaded'}</p>
    </div>
  )
}

export const viewsBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics.viewCount}
    </div>
  )
}

export const likesBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics.likeCount}
    </div>
  )
}

export const commentBodyTemplate = (rowData) => {
  return (
    <div className={'statsTemplate'}>
      {rowData.statistics.commentCount}
    </div>
  )
}
