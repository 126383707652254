import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import noImage from "../../img/no-image-1-gray.png";
import { CloseIcon, UploadImageIcon } from '../../img/Icons';
import NewCampaignCoverCropper from '../../NewCampaignCoverCropper/NewCampaignCoverCropper';
import './ImageUploader.css';
import NewImageCropper from '../NewImageCropper/NewImageCropper';
import { checkForImageValidations } from '@/utils/campaign-utils';
import { useToastModule } from '@/modules';



const ImageUploader = ({ currentTab, coverImage, onImageChange, isCreatorProfile }) => {
  const toast = useToastModule();
  const fileInputRef = useRef(null);
  const [availablePhotos, setAvailablePhotos] = useState('');
  const [openCropper, setOpenCropper] = useState(false);

  useEffect(() => {
    onImageChange(coverImage);
    setAvailablePhotos(coverImage !== noImage ? coverImage : '');
  }, [coverImage]);

  const onTemplateSelectCover = async (e) => {
    const files = Array.from(e.files || e.target.files);
    if (files && files.length > 0) {
      const file = files[0];
      const fileCheck = await checkForImageValidations(file, !isCreatorProfile);
      if (fileCheck instanceof Error) {
        toast.showError(fileCheck.message)
        e.target.value = null;
      } else {
        if (files.length > 0) {
          const reader = new FileReader();
          reader.onload = () => {
            setAvailablePhotos(reader.result);
            onImageChange(reader.result);
          };
          reader.readAsDataURL(files[0]);
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const headerTemplate = () => (
    <>
      <div onClick={handleImageClick}>
        <p className='ImageType'>{`${isCreatorProfile ? "Profile Image" : "Banner Image"}`}</p>
        <Image src={coverImage} alt='cover image' className='CoverImage' />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          multiple
          onChange={onTemplateSelectCover}
        />
      </div>
      <span className='ImageInstruction'>Image dimension should be 2400x1300 and the size should be 1.1 MB only.</span>
    </>
  );

  return (
    <div className={`CoverImageWrapper ${isCreatorProfile ? "ProfileImage" : ""
      }`}>
      <FileUpload
        multiple
        accept="image/*"
        maxFileSize={1000000}
        headerTemplate={headerTemplate}
        chooseOptions={{
          className: 'p-button-outlined',
          style: { display: 'none' },
        }}
      />
      <Button title='Upload Image' className={`uploadButtonIcon ${availablePhotos ?
        `hasImage ${currentTab === 1 ? 'first-tab' : 'rest-tab'}`
        : ""}`} onClick={() => setOpenCropper(true)}>
        <UploadImageIcon scale={1}></UploadImageIcon>
      </Button>

      {/* {currentTab === 1 && availablePhotos && (
        <Button disabled={!availablePhotos} title='Remove Image' className='cropButtonIcon removeImage' onClick={() => {
          setAvailablePhotos(noImage);
          onImageChange(noImage);
        }}>
          <CloseIcon scale={2} />
        </Button>
      )} */}
      {currentTab === 1 && availablePhotos && (
        <Button disabled={!availablePhotos} title='Crop Image' className='CropButton' onClick={() => setOpenCropper(true)}>
          Crop Image
        </Button>
      )}
      {isCreatorProfile
        ? <NewImageCropper visible={openCropper} onHide={() => setOpenCropper(false)} image={coverImage} setCroppedPhoto={onImageChange} />
        : <NewCampaignCoverCropper visible={openCropper} close={() => setOpenCropper(false)} image={coverImage} setNewCover={onImageChange} />
      }

    </div>
  );
};

export default ImageUploader;
