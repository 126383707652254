import React, { useEffect, useState } from "react";
import "./NewPendingVideos.css";
import { ExpandArrowUp, ExpandArrowDown, CloseIcon } from "../img/Icons"
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { formatDate } from "@/utils/globals/date-utils";
import noImage from "../../img/no-image.png";
import { Button } from "primereact/button";
import { ContentStatus } from "@/data/types";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Paginator } from 'primereact/paginator';
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';

const statusTabs = [
  { value: 'Pending', type: ContentStatus.Pending },
  { value: 'Approved', type: ContentStatus.Accepted },
  { value: 'Rejected', type: ContentStatus.Rejected },
];

const dateBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {formatDate(data.createdAt)}
    </div>
  );
};

const titleBodyTemplate = (data) => {
  return (
    <div className={'titleTemplate'} onClick={() => window.open(`https://www.youtube.com/watch?v=${data.vid}`, "_blank")}>
      <Image src={data.previewPhoto ? data?.previewPhoto : noImage} alt={'thumbnail'} className={'titleTemplateImage'} />
      {data.title}
    </div>
  );
};

const statusPendingBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusPending'}>
      Pending
    </div>
  );
};

const statusApprovedBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusApproved'}>
      Approved
    </div>
  );
};

const statusRejectedBodyTemplate = () => {
  return (
    <div className={'statusTemplate statusRejected'}>
      Rejected
    </div>
  );
};

const authorTypeBodyTemplate = (data) => {
  return (
    <div className={'defaultTextTemplate'}>
      {(data.owner[0]?.type.charAt(0).toUpperCase() + data.owner[0]?.type.slice(1)) || data.owner?.type}
    </div>
  );
};

const authorBodyTemplate = (data) => {
  return (
    <div className={'authorTemplate'}>
      <Image src={data.owner[0]?.profile?.photo || data.owner?.profile?.photo || noImage} alt={'profile photo'} className={'authorTemplateImage'} />
      {data.owner[0]?.name || data.owner?.name}
    </div>
  );
};

const rowExpansionTemplate = (rowData) => {
  return (
    <div className={'expansionCommentTemplate'}>
      <div className={'darkLine'} />
      <p className={'rejectionLabel'}>Reason for rejection: </p>
      <p className={'rejectionText'}>{rowData.reasonReject}</p>
    </div>
  )
}

export default function (props) {
  const {
    loadingState, videos, changeStatus, limit, pageChange, search
  } = props;
  const [activeTab, setActiveTab] = useState(ContentStatus.Pending);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [approveReason, setApproveReason] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [first, setFirst] = useState(0);
  const [time, setTime] = useState("");

  const handleTabChange = (item) => {
    setActiveTab(item.type);
    changeStatus(item.type);
    setSearchValue('');
    setFirst(0);
  }

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className={'actionsTemplate'}>
        <Button className={'approveButton'} onClick={() => { setOpenApproveDialog(true); setSelectedVideo(rowData) }}>Approve</Button>
        <Button className={'rejectButton'} onClick={() => { setOpenRejectDialog(true); setSelectedVideo(rowData) }}>Reject</Button>
      </div>
    );
  };

  const handleRejectVideo = () => {
    props.rejectVideo(selectedVideo._id || selectedVideo.id, rejectReason).then(() => {
      setRejectReason('');
      setOpenRejectDialog(false);
    });
  }

  const handleApproveVideo = () => {
    props.approveVideo(selectedVideo._id || selectedVideo.id, approveReason).then(() => {
      setApproveReason('');
      setOpenApproveDialog(false);
    });
  }

  const campaignBodyTemplate = (data) => {
    return (
      <div className={'underlinedTextTemplate'} onClick={() => props.goToCampaign(data.campaignId?.id)}>
        {data.campaignId?.name || data.campaignId[0]?.name}
      </div>
    );
  };

  const expanderTemplate = (rowData) => {
    return (
      <div className={'expanderTemplate'}>
        {expandedRows.includes(rowData) ?
          <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
            <ExpandArrowUp />
          </div> :
          <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
            <ExpandArrowDown />
          </div>
        }
      </div>
    )
  }
  const onPageChange = (event) => {
    setFirst(event.first);
    pageChange(event)
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setFirst(0);
        search(e.target.value);
      }, 500)
    );
  }
  useEffect(() => {
    setNoResults(!(videos?.pendingVideos?.length))
  }, [videos]);

  return (
    <div className={'pending-videos'}>
      <p className={'title'}>Pending Videos YT</p>
      <div className={'tabsWrapper'}>
        <div className={'tabs'}>
          {statusTabs.map((item) =>
            <div key={item.type} className={item.type === activeTab ? 'tabSelected' : 'tabNormal'}
              onClick={() => handleTabChange(item)}>
              {item.value}
            </div>)}
        </div>
      </div>
      <span className="p-input-icon-left inputFieldWrapper">
        <i className="pi pi-search" />
        <InputText className={'inputField'} placeholder={'Search for video'} value={searchValue} onChange={(e) => handleSearch(e)} />
      </span>
      {activeTab === ContentStatus.Pending &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage={"No videos found."} loading={loadingState}
          loadingIcon={
            <CustomLoader />
          }
          style={{ minHeight: 500 }}>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field={"title"} header={"Title"} body={titleBodyTemplate} sortable style={{ width: '22.5%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusPendingBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field={"campaignId.name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={""} header={"Author type"} body={authorTypeBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field={"owner[0].name"} header={"Author"} body={authorBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={""} header={""} body={actionsBodyTemplate}></Column>
        </DataTable>
      }
      {activeTab === ContentStatus.Accepted &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage={"No videos found."} loading={loadingState}
          loadingIcon={
            <CustomLoader />
          }
          style={{ minHeight: 500 }}>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '10%' }}></Column>
          <Column field={"title"} header={"Title"} body={titleBodyTemplate} sortable style={{ width: '25%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusApprovedBodyTemplate} style={{ width: '15%' }}></Column>
          <Column field={"campaignId.name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '17.5%' }}></Column>
          <Column field={""} header={"Author type"} body={authorTypeBodyTemplate} style={{ width: '15%' }}></Column>
          <Column field={"owner[0].name"} header={"Author"} body={authorBodyTemplate} sortable style={{ width: '17.5%' }}></Column>
        </DataTable>
      }
      {activeTab === ContentStatus.Rejected &&
        <DataTable value={videos?.pendingVideos} removableSort emptyMessage={"No videos found."} rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} loading={loadingState}
          loadingIcon={
            <CustomLoader />
          }
          style={{ minHeight: 500 }}>
          <Column field={"createdAt"} header={"Date"} body={dateBodyTemplate} sortable style={{ width: '7.5%' }}></Column>
          <Column field={"title"} header={"Title"} body={titleBodyTemplate} sortable style={{ width: '22.5%' }}></Column>
          <Column field={"status"} header={"Status"} body={statusRejectedBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field={"campaignId.name"} header={"Campaign"} body={campaignBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column field={""} header={"Author type"} body={authorTypeBodyTemplate} style={{ width: '12.5%' }}></Column>
          <Column field={"owner[0].name"} header={"Author"} body={authorBodyTemplate} sortable style={{ width: '15%' }}></Column>
          <Column expander={true} body={expanderTemplate} />
        </DataTable>
      }
      {!noResults && <Paginator first={first} rows={limit} totalRecords={videos?.count} onPageChange={onPageChange} style={{ background: '#130A23' }} />}
      <Dialog visible={openApproveDialog} onHide={() => setOpenApproveDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => { setOpenApproveDialog(false); setApproveReason('') }}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>APPROVE VIDEO</p>
          <p className={'reasonText'}>Before approving the video <strong>({selectedVideo.title})</strong>, give reasoning why.</p>
          <InputTextarea value={approveReason} onChange={(e) => setApproveReason(e.target.value)} rows={5} className={'reasonTextarea'} placeholder={'Approve Reason'} />
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} onClick={() => { setOpenApproveDialog(false); setApproveReason('') }}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={!approveReason} onClick={() => handleApproveVideo()}>Approve</Button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={openRejectDialog} onHide={() => setOpenRejectDialog(false)} className={'reasonDialog'}>
        <div className={'closeRow'}>
          <div className={'closeButton'} onClick={() => { setOpenRejectDialog(false); setRejectReason('') }}><CloseIcon /></div>
        </div>
        <div className={'reasonContent'}>
          <p className={'reasonTitle'}>REJECT VIDEO</p>
          <p className={'reasonText'}>Before rejecting the video <strong>({selectedVideo.title})</strong>, give reasoning why.</p>
          <InputTextarea value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} rows={5} className={'reasonTextarea'} placeholder={'Reject Reason'} />
          <div className={'reasonButtonGroup'}>
            <Button className={'reasonButtonCancel'} onClick={() => { setOpenRejectDialog(false); setRejectReason('') }}>Cancel</Button>
            <Button className={'reasonButtonSave'} disabled={!rejectReason} onClick={() => handleRejectVideo()}>Reject</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
