import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Image } from "primereact/image";
import { Chart } from 'primereact/chart';
import { Button } from "primereact/button";
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { InputTextarea } from 'primereact/inputtextarea';

import noImage from "../img/no-image.png";
import youtubeIcon from "@/react_app/img/youtube.png"
import CustomLoader from '../Other/CustomLoader/CustomLoader';
import { ExpandArrowDown, ExpandArrowUp } from '../img/Icons';
import DeleteApplication from "@/react_app/components/Dialogs/DeleteApplication";
import { FilterClearIcon, WhitelistDateIcon, WhitelistDeleteActionIcon, WhitelistDropdownIcon } from '../NewAdmin/img/Icons';

import { formatDate } from '@/utils/globals/date-utils';
import { useWhitelisting, useAuth, useToastModule } from "@/modules";
import { calculateCpiRate } from '@/utils/globals/cpi-rate';

import "./CampaignWhitelisting.css"

const statusFilterOptions = [
  { name: "Pending", value: "pending" },
  { name: "Approved", value: "accepted" },
  { name: "Rejected", value: "rejected" },
];
const campaignBodyTemplate = (rowData) => (
  <div className='underlinedTextTemplate'>
    {rowData?.campaignName || '-'}
  </div>
)
const projectedViewsBodyTemplate = (rowData) => (
  <div className="brandTemplate">
    {Math.round(((rowData?.proposedPayoutCap || 0) / calculateCpiRate(rowData?.cpm?.amount, rowData.appliedFee)) * 1000).toLocaleString()}
  </div>
);
const paymentMethodBodyTemplate = (rowData) => (
  <div className='brandTemplate'>
    {rowData?.paymentMethod || '-'}
  </div>
)
const brandBodyTemplate = (rowData) => (
  <div className='brandTemplate'>
    <Image src={rowData?.brand?.photo || noImage} alt='profile photo' className='authorTemplateImage' />
    {rowData?.brand?.name || ""}
  </div>
);
const requestDateBodyTemplate = (rowData) => (
  <div className='defaultTextTemplate'>
    {formatDate(rowData.createdAt)}
  </div>
);
const applicantBodyTemplate = (rowData) => {
  const openChannel = (channelId) => {
    window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
  };
  const renderTemplate = (photo, title, channelId) => (
    <div className='titleTemplate' onClick={() => channelId && openChannel(channelId)}>
      <Image src={photo || noImage} alt='thumbnail' className='titleTemplateImage' />
      {title}
    </div>
  );

  if (!rowData?.creator) return <div className='titleTemplate'>N/A</div>;

  const { creator, channel } = rowData;
  const {
    type, name, photo, youtube, agencyWorkers
  } = creator;

  let applicantDetails;
  if (type === "agencyOwner" || (type === "creator" && channel)) {
    applicantDetails = agencyWorkers?.find(worker => worker?.channel?.channelId === channel);
    if (applicantDetails) {
      const channelDetails = applicantDetails?.channel || {};
      return renderTemplate(channelDetails.photo, channelDetails.title, channelDetails.channelId);
    } else return renderTemplate(photo, name, youtube?.[0]?.id);

  }
  return renderTemplate(photo, name, youtube?.[0]?.id);
};
const statusBodyTemplate = (rowData) => (
  <div className={`statusTemplate ${rowData.status}`}>{(rowData.status === 'accepted') ? "Approved" : rowData.status}</div>
)

const CampaignWhitelisting = (props) => {

  const { campaignId, campaign } = props;

  const { user } = useAuth();
  const toast = useToastModule();
  const {
    manager: {
      getCpmCampaignApplications,
      resolveRequestsAction,
      deleteRequestsAction,
      undoRequestsAction,
      resolveRequestsError,
    },
  } = useWhitelisting();

  const [time, setTime] = useState("");
  const [skip, setSkip] = useState(0);
  const [dates, setDates] = useState({ date: null, filterDate: null });
  const [status, setStatus] = useState("pending");
  const [allRequest, setAllRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [totalRequest, setTotalRequest] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState(null);
  const [updatedCurrentNonSelectedValue, setUpdatedCurrentNonSelectedValue] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentCampaignBudget, setCurrentCampaignBudget] = useState(null);

  const limit = 10;
  const activeTab = 'brand'
  const dateRangeDebounceRef = useRef(null);
  const isFilterDisabled = isActionLoading || isLoading;
  const isActionDisabled = (["rejected", 'accepted'].includes(status)) || ((selectedRequests?.length <= 0 || selectedRequests == null)) || isActionLoading || isLoading;
  const totalPaidViews = Math.round((campaign?.budget?.amount / campaign?.cpm?.amount) * 1000);

  const handleGetRequests = (skip, status, search, date) => {
    setIsLoading(true);
    const updatedFilters = {
      campaignName: campaignId, username: search, brand: user.value?.brand?.id, approvalTab: "brand",
      date: date,
    };
    getCpmCampaignApplications(limit, skip, status, updatedFilters).then((capApplications) => {
      if (capApplications?.campaignApplications) {
        setAllRequest(capApplications?.campaignApplications);
        setTotalRequest(capApplications?.maxCount);
        setCurrentCampaignBudget(capApplications?.budget);
      } else {
        setAllRequest([]);
        setTotalRequest(0);
        setCurrentCampaignBudget(null);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log('error', error);
      setIsLoading(false);
    });
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        setSkip(0);
        handleGetRequests(0, 'pending', value, dates.filterDate);
      }, 800)
    );
  };
  const handleClearSearch = () => {
    if (searchValue) {
      setSearchValue('');
      setFirst(0);
      handleGetRequests(0, status, '', dates.filterDate);
    }
  }
  const handleChangeStatus = (value) => {
    setStatus(value);
    setAllRequest([]);
    setSkip(0);
    handleGetRequests(0, value, searchValue, dates.filterDate);
    setSelectedRequests(null)
    setUpdatedCurrentNonSelectedValue(null);
  }
  const handlePagination = (event) => {
    const { first } = event;
    setSkip(first);
    handleGetRequests(first, status, searchValue, dates.filterDate);
    setUpdatedCurrentNonSelectedValue(null);
  };
  const handleDateFilter = (e) => {
    const { value } = e.target;
    if (e.value && value) {
      setDates((prev) => ({ ...prev, date: e.value }));
      const filterDateRange = value?.map((date) => { return formatDate(date, 'YYYY-MM-DD') });
      if (value == null) {
        setDates({ date: null, filterDate: null });
      }
      if (dateRangeDebounceRef.current) {
        clearTimeout(dateRangeDebounceRef.current);
      }
      if (filterDateRange?.filter(date => date).length > 1)
        dateRangeDebounceRef.current = setTimeout(() => {
          setDates({ date: e.value, filterDate: filterDateRange });
          handleGetRequests(skip, status, searchValue, filterDateRange)
        }, 800);
    } else {
      setDates({ date: null, filterDate: null });
      handleGetRequests(skip, status, searchValue, null)
    }
  };
  const handleSelectedValues = (inputName, inputValue, applicationId) => {
    setUpdatedCurrentNonSelectedValue(prevState => ({
      ...prevState,
      [applicationId]: {
        ...(prevState?.[applicationId] ?? {}),
        [inputName]: inputValue
      }
    }));

    const updatedSelectedValues = [...selectedRequests].map(selectedReq => {
      if (selectedReq.applicationId === applicationId) {
        selectedReq[inputName] = inputValue;
      }
      return selectedReq
    });
    setSelectedRequests(updatedSelectedValues);
  };
  const handleResolveRequests = (action, selectedRequestsData, singleAction) => {
    const isRejectAction = action === "reject";
    const errors = new Set();
    setIsActionLoading(true);
    const requestPayload = selectedRequestsData.map(request => {
      const updatedPayload = {
        userId: request?.creator?.id,
        campaignId: request?.campaignId,
        applicationId: request?.applicationId,
        perspective: 'brand',
        proposedPayoutCap: Number(request?.proposedPayoutCap),
        contractWork: false,
        paymentMethod: request?.paymentMethod,
        brand_reason: singleAction ? (updatedCurrentNonSelectedValue ? updatedCurrentNonSelectedValue[request?.applicationId]?.brand_reason : "") : request.brand_reason
      };
      if (request?.paymentMethod !== "CPM" || isRejectAction) {
        delete updatedPayload.proposedPayoutCap;
      }
      if (request?.creator?.type === "agencyOwner" || (request?.creator?.type === "creator" && request?.channel)) {
        updatedPayload.channelId = request?.creator?.agencyWorkers.find(channel => channel?.channel?.channelId === request?.channel)?.channel?.channelId;
      }
      return updatedPayload;
    })

    if (errors.size > 0) {
      errors.forEach(error => toast.showError(error));
      setIsActionLoading(false);
      return;
    }
    resolveRequestsAction(action, requestPayload).then((res) => {
      if (res) {
        setIsActionLoading(false);
        handleGetRequests(skip, status, searchValue);
        toast.showSuccess(`Requests ${action.includes('accept') ? "accepted" : "rejected"} successfully!`);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        setDates({ date: null, filterDate: null });
        setSearchValue('');
      } else {
        setIsActionLoading(false);
        toast.showError(resolveRequestsError.value.message || "Something went wrong, please try again!");
      }
    }).catch((error) => {
      if (error) {
        setIsActionLoading(false);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        toast.showError(error.message || "Something went wrong, please try again!");
        setDates({ date: null, filterDate: null });
        setSearchValue('');
      }
    });
  };
  const handleDeleteRequests = (selectedRequestsData) => {
    setIsActionLoading(true);
    deleteRequestsAction(selectedRequestsData).then((res) => {
      if (res) {
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        setIsActionLoading(false);
        setOpenDeleteDialog(false);
        toast.showSuccess("Requests deleted successfully!");
        handleGetRequests(skip, status, searchValue, dates.filterDate);
      }
    }).catch((error) => {
      if (error) {
        setIsActionLoading(false);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
        toast.showError(error.message || "Something went wrong, please try again!");
      }
    });
  };
  const handleUndoRequest = async () => {
    setIsActionLoading(true);
    const requestPayload = await selectedRequests.map(request => {
      return {
        campaignId: request?.campaignId,
        applicationId: request?.applicationId,
        perspective: activeTab,
      };
    })
    undoRequestsAction(requestPayload).then((res) => {
      setIsActionLoading(false);
      if (res) {
        toast.showSuccess("Requests undo successfully!");
        handleGetRequests(skip, status, searchValue, dates.filterDate);
        setSelectedRequests(null);
        setUpdatedCurrentNonSelectedValue(null);
      }
    }).catch((error) => {
      if (error) toast.showError(error.message || "Something went wrong, please try again!");
      setIsActionLoading(false);
    });
  }
  const viewTemplate = () => (
    <>
      <Chart type="doughnut" data={dataMain} options={optionsMain} className='chartGraphic' />
      <div className='chartPercentage'>{100 - (Math.ceil((currentCampaignBudget?.viewCount / totalPaidViews) * 100))}%</div>
    </>
  );
  const expanderTemplate = (rowData) => (
    <div className='expanderTemplate'>
      {status === 'pending' && <>
        <Button className='successIcon' icon="pi pi-check" severity="success" aria-label="Bookmark" tooltip='Approve'
          disabled={isActionLoading || isLoading} onClick={() => { handleResolveRequests("accept", [rowData], true) }} />
        <Button className='rejectIcon' icon="pi pi-times" severity="danger" aria-label="Cancel" tooltip='Reject'
          disabled={isActionLoading || isLoading} onClick={() => { handleResolveRequests("reject", [rowData], true) }} />
      </>
      }
      {expandedRows.includes(rowData) ?
        <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
          <ExpandArrowUp />
        </div> :
        <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
          <ExpandArrowDown />
        </div>
      }
    </div>
  );
  const rowExpansionTemplate = (rowData) => {
    const getApplicantDetails = (creator, channel) => {
      const { type, name, agencyWorkers } = creator;
      const hasChannel = !!channel;

      if (type === "agencyOwner" || (type === "creator" && hasChannel)) {
        const subscribedChannel = agencyWorkers?.find(worker => worker?.channel?.channelId === channel)?.channel;
        return {
          agencyChannel: hasChannel ? name : "N/A",
          applicantType: hasChannel ? "Agency" : "Creator",
          subscribedAgencyCreatorChannel: subscribedChannel,
        };
      }
      return { agencyChannel: "N/A", applicantType: "Creator", subscribedAgencyCreatorChannel: null };
    };

    const { agencyChannel, applicantType, subscribedAgencyCreatorChannel } = rowData?.creator
      ? getApplicantDetails(rowData.creator, rowData.channel)
      : { agencyChannel: "N/A", applicantType: "Creator", subscribedAgencyCreatorChannel: null };

    const updatedCurrentSelectedValue = selectedRequests?.find(
      selectedReq => selectedReq?.applicationId === rowData?.applicationId
    );

    const handleInputChange = (name, value, applicationId) => {
      handleSelectedValues(name, value, applicationId);
      if (name === 'proposedPayoutCap') {
        const views = (value / rowData?.cpm?.amount) * 1000;
        handleSelectedValues('currentViews', views, applicationId);
      }
    };

    return (
      <>
        <div className='darkLine' />
        <div className='expansionWrapper'>
          <div className='expansionDataSquare'>
            <div className='request-info type-name'>
              <ul>
                <li>
                  <p className='info-label'>Agency Channel :</p>
                  <p className='info-value'>{agencyChannel}</p>
                </li>
                <li>
                  <p className='info-label'>Applicant Type :</p>
                  <p className='info-value'>{applicantType}</p>
                </li>
                <li>
                  <p className='info-label'># of Collaborations with Brand :</p>
                  <p className='info-value'>{rowData?.collaborationsCount}</p>
                </li>
              </ul>
              <div className='request-reason'>
                <div className='brand-reason'>
                  <InputTextarea
                    autoResize
                    name="brand_reason"
                    value={(updatedCurrentSelectedValue?.brand_reason || rowData?.brand_reason)}
                    onChange={(e) => handleInputChange('brand_reason', e.target.value, rowData?.applicationId)}
                    rows={5}
                    className='textArea'
                    placeholder='Enter the reason.'
                  />
                </div>
              </div>
            </div>
            <div className={`request-info max-cap ${activeTab}`}>
              <ul>
                {rowData.paymentMethod === "CPM" && (
                  <li>
                    <div className='info-input'>
                      <div className={`sub-info ${activeTab}`}>
                        <p className='sub-info-label'>Projected Views :</p>
                        <p className='sub-info-value'>
                          {Math.floor(
                            ((updatedCurrentSelectedValue?.proposedPayoutCap || rowData?.proposedPayoutCap || 0) /
                              calculateCpiRate(rowData?.cpm?.amount, rowData?.appliedFee) || 0) *
                            1000
                          ) || 0}
                        </p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            <div className='request-info youtube-info'>
              {subscribedAgencyCreatorChannel && applicantType === "Agency" &&
                <ul className='youtube-links'>
                  <li>
                    <div className='youtube-link'>
                      <img className='info-icon' alt="youtube-logo" src={youtubeIcon} height="20" width="20" />
                      <a
                        className='info-link'
                        href={`https://www.youtube.com/channel/${subscribedAgencyCreatorChannel?.channelId}`}
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        https://www.youtube.com/channel/{subscribedAgencyCreatorChannel?.channelId}
                      </a>
                    </div>
                    <div className='youtube-sub'>
                      <p className='info-label'>Subscribers :</p>
                      <p className='info-value'>{subscribedAgencyCreatorChannel?.followers}</p>
                    </div>
                  </li>
                </ul>
              }
              {rowData?.creator && applicantType === "Creator" && rowData?.creator?.youtube?.length > 0 &&
                <ul className='youtube-links'>
                  {rowData?.creator?.youtube?.map((youtube, index) => (
                    <li key={youtube?.id + index}>
                      <div className='youtube-link'>
                        <img className='info-icon' alt="youtube-logo" src={youtubeIcon} height="20" width="20" />
                        <a
                          className='info-link'
                          href={`https://www.youtube.com/channel/${youtube?.id}`}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          https://www.youtube.com/channel/{youtube?.id}
                        </a>
                      </div>
                      <div className='youtube-sub'>
                        <p className='info-label'>Subscribers :</p>
                        <p className='info-value'>{youtube?.followers || 0}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              }
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (campaignId)
      handleGetRequests(skip, status, searchValue, dates.filterDate);
  }, []);

  const dataMain = useMemo(() => ({
    datasets: [{
      data: [totalPaidViews - currentCampaignBudget?.viewCount, currentCampaignBudget?.viewCount || 0],
      backgroundColor: ['#E73D38', 'lightgray'],
      borderWidth: 0,
    }],
  }), [currentCampaignBudget]);

  const optionsMain = {
    cutout: '70%',
    plugins: { legend: { display: false } },
    interaction: { mode: false, intersect: false },
  };
  const memoizedViewTemplate = useMemo(() => viewTemplate(), [currentCampaignBudget]);

  return (
    <div className='campaignWhitelisting'>
      <p className='whitelistingTitle'>Whitelisting</p>
      <div className='filter-action-wrap' >
        <ul className='filters'>
          <li className='filter p-input-icon-left search-wrap'>
            <i className="pi pi-search" />
            <InputText className='input-field' placeholder='Search for creator' value={searchValue} onChange={(e) => handleSearch(e)}
              disabled={isFilterDisabled}
            />
            <span className='clear-icon' onClick={() => handleClearSearch()}>
              <FilterClearIcon />
            </span>
          </li>
          <li className='filter date-range-wrap'>
            <Calendar icon={<WhitelistDateIcon />} iconPos='right' value={dates.date} showIcon
              onChange={(e) => handleDateFilter(e)}
              selectionMode="range"
              showButtonBar
              className='daterange'
              placeholder='Search by date'
              hideOnRangeSelection
              disabled={isFilterDisabled}
            />
          </li>
          <li className='filter dropdown-wrap status'>
            <Dropdown value={status} options={statusFilterOptions} optionLabel="name"
              placeholder="Search by status" onChange={(e) => handleChangeStatus(e.target.value)}
              className="w-full md:w-14rem" dropdownIcon={<WhitelistDropdownIcon scale={0.6}
                disabled={isFilterDisabled}
              />} />
          </li>
        </ul>
        <div className='action-wrap'>
          {status !== 'rejected' &&
            <>
              <Button className='button approve-button' disabled={isActionDisabled}
                onClick={() => { handleResolveRequests("accept", selectedRequests, false) }}>Approve</Button>
              <Button className='button reject-button' disabled={isActionDisabled}
                onClick={() => { handleResolveRequests('reject', selectedRequests, false) }}>Reject</Button>
            </>
          }
          {status === 'rejected' &&
            <Button className='button undo-button' icon="pi pi-undo" loading={isActionLoading}
              disabled={(selectedRequests?.length <= 0 || selectedRequests == null)} onClick={() => handleUndoRequest()}>Undo</Button>
          }
          {<Button className='delete-button' rounded text severity="secondary" aria-label="delete"
            onClick={() => setOpenDeleteDialog(true)} disabled={isActionDisabled}>
            <WhitelistDeleteActionIcon />
          </Button>}
        </div>
      </div>

      {(status === 'accepted' && campaign.paymentMethod === "CPM" && allRequest.length > 0) &&
        <div className="container">
          <div className="left">
            <div className="info">
              <span className="label">Budget:</span>
              <span className="value">$ {campaign?.budget?.amount || 0}</span>
            </div>
            <div className="info">
              <span className="label">{campaign?.paymentMethod} :</span>
              {campaign?.paymentMethod === 'CPM' && <span className="value">{`$ ${campaign?.cpm.amount}`}</span>}
              {campaign?.paymentMethod === 'CPC' && <span className="value">{`$ ${campaign?.cpc?.amount}`}</span>}
              {campaign?.paymentMethod === 'CPI' && (
                <>
                  <span className="value">Android :{`$ ${campaign?.cpi?.android?.amount}`}</span>
                  <span className="value">IOS :{`$ ${campaign?.cpi?.ios?.amount}`}</span>
                </>)
              }
            </div>
            <div className="info">
              <span className="label">Paid Views:</span>
              <span className="value">{totalPaidViews.toLocaleString()}</span>
            </div>
          </div>
          <div className="divider"></div>
          <div className="right">
            {!!currentCampaignBudget && memoizedViewTemplate}
            <div className="info">
              <span className="label">Projected Views - Approved</span>
              <span className="value">{`${currentCampaignBudget?.viewCount} views`}</span>
            </div>
          </div>
        </div>}

      <DataTable value={allRequest} removableSort emptyMessage="No request found"
        loading={isLoading}
        loadingIcon={<CustomLoader />}
        selectionMode="checkbox"
        selection={selectedRequests}
        expandedRows={expandedRows}
        onSelectionChange={(e) => {
          setSelectedRequests(e.value);
          const selectedIds = e.value.map((item) => item.applicationId);
          setUpdatedCurrentNonSelectedValue(prevState => {
            const newState = { ...prevState };
            selectedIds.forEach(id => {
              if (newState[id]) {
                delete newState[id];
              }
            });
            return newState;
          });
        }}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        style={{ minHeight: 500 }}>
        <Column selectionMode="multiple" headerStyle={{ width: '2.5%' }} />
        <Column field="createdAt" header="Date" sortable body={requestDateBodyTemplate} style={{ width: '7%' }} />
        <Column field="name" header="Applicant Name" body={applicantBodyTemplate} style={{ width: '12.5%' }} />
        <Column field="campaignName" header="Campaign" sortable body={campaignBodyTemplate} style={{ width: '14%' }} />
        <Column field="proposedPayoutCap" header="Projected Views" sortable body={projectedViewsBodyTemplate} style={{ width: '14%' }} />
        <Column field="paymentMethod" header="Campaign Type" sortable body={paymentMethodBodyTemplate} style={{ width: '10%' }} />
        <Column field="brand" header="Brand" body={brandBodyTemplate} style={{ width: '12%' }} />
        <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '8%' }} />
        <Column expander={true} body={expanderTemplate} style={{ width: '5%' }} />
      </DataTable>
      {totalRequest > 0 && <Paginator first={skip} rows={limit} totalRecords={totalRequest} onPageChange={handlePagination} style={{ background: '#130A23' }} />}

      <DeleteApplication open={openDeleteDialog} close={() => setOpenDeleteDialog(false)} allRequest={selectedRequests} deleteRequest={() => handleDeleteRequests(selectedRequests)} />
    </div >
  )
}

export default CampaignWhitelisting
