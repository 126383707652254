
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useAdmins } from '@/modules';
import { formatDate } from '@/utils/globals/date-utils';
import OldPendingVideos from './OldPendingVideos.vue';
import NewPendingVideos from '@/react_app/NewAdmin/NewPendingVideos/NewPendingVideos';
import { applyPureReactInVue } from 'veaury';
import { isNewAdminEnabled } from '@/utils/globals/feature-flags';
import { ContentStatus } from '@/data/types/content-types';
import router from '@/router';

export default defineComponent({
  components: {
    NewPendingVideos: applyPureReactInVue(NewPendingVideos),
    OldPendingVideos,
  },
  setup() {
    const adminsModule = useAdmins();
    const rejectReason = ref('');
    const approveReason = ref('');
    const seeRejectReason = ref(false);
    const seeApproveReason = ref(false);
    const currentRejectVideoId = ref();
    const currentApproveVideoId = ref();
    const selectedQuery = ref('pending');
    const filterAuthorName = ref('');
    const ownerName = ref('');
    const selectedOwnerType = ref('creator');
    const dateFilter = ref<any[]>();
    const filterCampaignName = ref('');
    const loadingState = ref(true);
    const limit = ref(10);
    const skip = ref(0);
    const searchValue = ref('');
    const queryesStatus = [
      { name: 'Pending', value: 'pending' },
      { name: 'Accepted', value: 'accepted' },
      { name: 'Rejected', value: 'rejected' },
    ];

    const getPendingVideoList = (
      skip: number,
      status: string,
      search: string
    ) => {
      loadingState.value = true;
      adminsModule.manager
        .getCampaignsWithPendingVideoCpm(limit.value, skip, status, {
          campaignName: search,
          authorName: filterAuthorName.value,
          date: dateFilter.value,
        })
        .then(() => (loadingState.value = false));
    };

    const approveVideo = async (
      selectedVideoId: string,
      approveReason: string
    ) => {
      adminsModule.manager
        .approvalCpmVideo(selectedVideoId, approveReason)
        .then((data) => {
          if (data?.success) {
            getPendingVideoList(skip.value, selectedQuery.value, searchValue.value);
          }
        });
    };

    const changeStatusQuery = () => {
      skip.value = 0;
      searchValue.value = '';
      getPendingVideoList(0, selectedQuery.value, '');
    };

    watch(dateFilter, () => changeStatusQuery());

    const rejectVideo = async (
      selectedVideoId: string,
      rejectReason: string
    ) => {
      adminsModule.manager
        .rejectCpmVideo(selectedVideoId, rejectReason)
        .then((data) => {
          if (data?.success) {
            getPendingVideoList(skip.value, selectedQuery.value, searchValue.value);
          }
        });
    };

    const debounce = (func: { (): void; apply?: any }, timeout = 400) => {
      let timer: NodeJS.Timeout;
      return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    };

    const searchVideos = (search: string) => {
      skip.value = 0;
      searchValue.value = search;
      getPendingVideoList(0, selectedQuery.value, search);
    };

    const processChange = debounce(() => changeStatusQuery());

    const changeStatus = (newStatus: ContentStatus) => {
      skip.value = 0;
      selectedQuery.value = newStatus;
      getPendingVideoList(0, newStatus, searchValue.value);
    };

    const goToCampaign = (itemId: any) => {
      router.push({ name: 'campaign', params: { campaignID: itemId } });
    };
    const pageChange = (data: { first: number }) => {
      skip.value = data.first;
      getPendingVideoList(data.first, selectedQuery.value, searchValue.value);
    };

    onMounted(() => {
      getPendingVideoList(0, 'pending', searchValue.value);
    });

    return {
      contentNeedActionAdmin:
        adminsModule.manager.getCampaignsWithPendingVideoCpmResult,
      approveVideo,
      rejectVideo,
      changeStatusQuery,
      formatDate,
      loadingState,
      approveReason,
      seeApproveReason,
      currentApproveVideoId,
      processChange,
      rejectReason,
      currentRejectVideoId,
      seeRejectReason,
      selectedQuery,
      queryesStatus,
      dateFilter,
      ownerName,
      selectedOwnerType,
      filterAuthorName,
      filterCampaignName,
      isNewAdminEnabled,
      changeStatus,
      goToCampaign,
      limit,
      pageChange,
      searchVideos,
    };
  },
});
