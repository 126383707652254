import { CampaignData, CampaignPaymentMethod, TrackingLink } from '@/data/types';
import { EarningsStats, RegionalTierStats } from '@/data/types/earning-types';
import { calculateCpiRate } from '@/utils/globals/cpi-rate';
import { FILE_DIMENSION, FILES, IMAGE_FORMAT } from './globals/constants';


// get current metrics by all time
export const findCurrentMetricsChannel = (channelIdOrUserId: string, campaignWithContract: CampaignData | undefined, isAgency = true): EarningsStats | [] => {
  if (!campaignWithContract || !channelIdOrUserId) {
    return [];
  }

  let currentTrackingLink: TrackingLink | undefined;

  if (isAgency) {
    currentTrackingLink = campaignWithContract?.trackingLinks.find((link: TrackingLink) => link?.channelId
      === channelIdOrUserId);
  } else {
    currentTrackingLink = campaignWithContract?.trackingLinks.find((link: TrackingLink) => link?.creatorId
      === channelIdOrUserId);
  }

  if (!currentTrackingLink) {
    return [];
  }
  const campaignPaymentType = campaignWithContract.paymentMethod || "CPI";
  const { appliedFee } = campaignWithContract;

  const allowedRegions: string[] = [];

  campaignWithContract.desiredRegions.forEach((region: { value: string }) => {
    if (region.value === "Tier 1 - Global") {
      allowedRegions.push("tierOne");
    }
    if (region.value === "Tier 2 - Europe") {
      allowedRegions.push("tierTwo");
    }
    if (region.value === "Tier 3 - CEE") {
      allowedRegions.push("tierThree");
    }
    if (region.value === "ASIA & CASA") {
      allowedRegions.push("tierFour");
    }
  });

  const currentStats = {
    android: 0,
    IOS: 0,
    clicks: 0,
    conversions: 0,
    forPay: {},
    link: "",
    views: 0,
  };

  allowedRegions.forEach((region: string) => {
    const currentTierStats = currentTrackingLink![region as keyof TrackingLink] as RegionalTierStats;

    const currentPaidStats = currentTrackingLink?.paidFor;

    if (!currentPaidStats) {
      currentStats.IOS += currentTierStats.IOS - currentTrackingLink!.paidFor!.IOS;
      currentStats.android += currentTierStats.android - currentTrackingLink!.paidFor!.android;
      currentStats.conversions += currentTierStats?.conversions || 0;
      currentStats.views += currentTierStats?.views || 0;
    }
  });

  if (campaignPaymentType === CampaignPaymentMethod.CPI && campaignWithContract?.cpi) {
    const forPayAndroid = currentStats.android * campaignWithContract.cpi.android.amount;
    const forPayIos = currentStats.IOS * campaignWithContract?.cpi?.ios?.amount;

    currentStats.forPay = {
      IOS: calculateCpiRate(forPayIos, appliedFee),
      android: calculateCpiRate(forPayAndroid, appliedFee),
      total: calculateCpiRate(forPayAndroid + forPayIos, appliedFee),
      currency: campaignWithContract?.budget?.currency,
    };
  }

  if (campaignPaymentType === CampaignPaymentMethod.CPC && campaignWithContract?.cpc) {
    currentStats.forPay = {
      amount: calculateCpiRate(currentStats.clicks * campaignWithContract?.cpc?.amount, appliedFee),
      currency: campaignWithContract?.budget?.currency,
    };
  }

  currentStats.link = currentTrackingLink?.link;
  return currentStats as EarningsStats;
};

// get current metrics by current mounth
export const findCurrentMetricsMontly = (channelIdOrUserId: any, campaignWithContract: CampaignData | undefined, isAgency = true) => {
  if (!campaignWithContract || !channelIdOrUserId) {
    return [];
  }
  let currentTrackingLink: any;
  if (isAgency) {
    currentTrackingLink = campaignWithContract?.trackingLinks.filter((el: any) => el?.channelId === channelIdOrUserId);
  } else {
    currentTrackingLink = campaignWithContract?.trackingLinks.filter((el: any) => el?.creatorId === channelIdOrUserId);
  }
  if (!currentTrackingLink?.length) {
    return [];
  }
  const campaignPaymentType = campaignWithContract.paymentMethod || "CPI";
  const { appliedFee } = campaignWithContract;
  currentTrackingLink = currentTrackingLink[0];

  const allowedRegions: string[] = [];
  campaignWithContract.desiredRegions.forEach((region: { value: string }) => {
    if (region.value === "Tier 1 - Global") {
      allowedRegions.push("tierOne");
    }
    if (region.value === "Tier 2 - Europe") {
      allowedRegions.push("tierTwo");
    }
    if (region.value === "Tier 3 - CEE") {
      allowedRegions.push("tierThree");
    }
    if (region.value === "ASIA & CASA") {
      allowedRegions.push("tierFour");
    }
  });
  const currentStats = {
    android: 0,
    IOS: 0,
    clicks: 0,
    conversions: 0,
    forPay: {},
    link: "",
  };
  allowedRegions.forEach((el: string) => {
    currentStats.IOS += currentTrackingLink[el].IOS - currentTrackingLink?.paidFor?.IOS || 0;
    currentStats.android += currentTrackingLink[el].android - currentTrackingLink?.paidFor?.android || 0;
    currentStats.clicks += currentTrackingLink[el].clicks - currentTrackingLink?.paidFor?.clicks || 0;
    currentStats.conversions += currentTrackingLink[el].conversions || 0;
  });

  if (campaignPaymentType === CampaignPaymentMethod.CPI && campaignWithContract?.cpi) {
    const forPayAndroid = currentStats.android * campaignWithContract.cpi.android.amount;
    const forPayIos = currentStats.IOS * campaignWithContract?.cpi?.ios?.amount;
    currentStats.forPay = {
      IOS: calculateCpiRate(forPayIos, appliedFee),
      android: calculateCpiRate(forPayAndroid, appliedFee),
      total: calculateCpiRate(forPayAndroid + forPayIos, appliedFee),
      currency: campaignWithContract?.budget?.currency,
    };
  }

  if (campaignPaymentType === CampaignPaymentMethod.CPC && campaignWithContract?.cpc) {
    currentStats.forPay = {
      amount: calculateCpiRate(currentStats.clicks * campaignWithContract?.cpc?.amount, appliedFee),
      currency: campaignWithContract?.budget?.currency,
    };
  }

  if (campaignPaymentType === CampaignPaymentMethod.CPM && campaignWithContract?.cpm) {
    // TODO
    currentStats.forPay = {
      amount: 0,
      currency: campaignWithContract?.budget?.currency,
    };
  }

  currentStats.link = currentTrackingLink?.link;
  return currentStats;
};

export const getStatisticsByRegion = (campaign: CampaignData | undefined, filteredWorkers?: any) => {
  const allowedRegions: string[] = [];
  if (campaign && campaign.desiredRegions) {
    campaign.desiredRegions.forEach((region) => {
      if (region.value === "Tier 1 - Global") {
        allowedRegions.push("tierOne");
      }
      if (region.value === "Tier 2 - Europe") {
        allowedRegions.push("tierTwo");
      }
      if (region.value === "Tier 3 - CEE") {
        allowedRegions.push("tierThree");
      }
      if (region.value === "ASIA & CASA") {
        allowedRegions.push("tierFour");
      }
    });
  }

  const statsByAllowedTiers = {
    clicks: 0,
    conversions: 0,
    android: 0,
    IOS: 0,
  };

  if (campaign && campaign.trackingLinks && filteredWorkers?.length) {
    const workersArray = filteredWorkers.map((worker: any) => worker?.channel?.channelId);
    campaign.trackingLinks.forEach((link: any) => {
      if (workersArray.includes(link?.channelId)) {
        allowedRegions.forEach((region) => {
          statsByAllowedTiers.clicks += link[region].clicks;
          statsByAllowedTiers.conversions += link[region].conversions;
          statsByAllowedTiers.android += link[region].android;
          statsByAllowedTiers.IOS += link[region].IOS;
        });
      }
    });
  }
  return statsByAllowedTiers;
};

export const getGlobalCampaignByRegion = (campaign: CampaignData | undefined) => {
  const allowedRegions: string[] = [];
  if (campaign && campaign.desiredRegions) {
    campaign.desiredRegions.forEach((region) => {
      if (region.value === "Tier 1 - Global") {
        allowedRegions.push("tierOne");
      }
      if (region.value === "Tier 2 - Europe") {
        allowedRegions.push("tierTwo");
      }
      if (region.value === "Tier 3 - Central and Eastern Europe") {
        allowedRegions.push("tierThree");
      }
      if (region.value === "ASIA & CASA") {
        allowedRegions.push("tierFour");
      }
    });
  }

  const statsByAllowedTiers = {
    clicks: 0,
    conversions: 0,
    android: 0,
    IOS: 0,
  };

  if (campaign && campaign.trackingLinks) {
    campaign.trackingLinks.forEach((link: any) => {
      allowedRegions.forEach((region) => {
        statsByAllowedTiers.clicks += link[region].clicks;
        statsByAllowedTiers.conversions += link[region].conversions;
        statsByAllowedTiers.android += link[region].android;
        statsByAllowedTiers.IOS += link[region].IOS;
      });
    });
  }
  return statsByAllowedTiers;
};

export const calculateMinimalBudgetCounter = (budget: number, paymentMethod: string, rate: any) => {
  if (paymentMethod === CampaignPaymentMethod.CPM) {
    const howToPay = 50 * rate?.amount;
    return budget >= howToPay;
  }
  if (paymentMethod === CampaignPaymentMethod.CPC) {
    const howToPay = 50 * rate?.amount;
    return budget >= howToPay;
  }
  if (paymentMethod === CampaignPaymentMethod.CPI) {
    const bigRate = rate?.android?.amount > rate?.ios?.amount ? rate?.android?.amount : rate?.ios?.amount;
    const howToPay = 50 * bigRate;
    return budget >= howToPay;
  }

  return false;
};

// Function to get image dimensions from a data URL
const getHeightAndWidthFromDataUrl = (dataURL: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.src = dataURL;
  });
};

// Function to get dimensions of a file
const getFileDimension = async (file: File): Promise<{ height: number; width: number } | null> => {
  try {
    if (!(file instanceof File)) return null;
    const fileAsDataUrl = URL.createObjectURL(file);
    const dimension = await getHeightAndWidthFromDataUrl(fileAsDataUrl);
    URL.revokeObjectURL(fileAsDataUrl); // Clean up the object URL
    return dimension;
  } catch {
    return null;
  }
};

// Function to validate image file
export const checkForImageValidations = async (file: File, checkDimension?: false): Promise<true | Error> => {
  try {
    const maxSize = FILES.CAMPAIGN_COVER_IMAGE;
    const { height, width } = FILE_DIMENSION;
    const fileTypes = IMAGE_FORMAT.split(',');
    const extension = file.name.split('.').pop()?.toLowerCase();

    // Check for valid file extension
    if (!extension || !fileTypes.includes(`.${extension}`)) {
      throw new Error(`${file.name} is not a valid file. Please upload a file in one of the following formats: ${IMAGE_FORMAT.split(',').map(ext => ext.replace(".", " ").toUpperCase()).join(',')}`);
    }

    // Check for file size
    if (file.size / 1024 / 1024 > maxSize) {
      throw new Error(`${file.name} file size needs to be less than ${maxSize} MB`);
    }

    // Check for file dimension
    if (checkDimension) {
      const dimension = await getFileDimension(file);
      if (!dimension) throw new Error('Failed to retrieve image dimensions.');
      if (dimension.height > height || dimension.width > width) {
        throw new Error(`The image dimensions need to be ${width} x ${height}. Current dimensions are ${dimension.width} x ${dimension.height}.`);
      }
    }
    return true;
  } catch (error) {
    return error as Error;
  }
};

export   const getRegion = (campaign:CampaignData, userType:string) =>{
    if(campaign?.id === '66fffab8c5ff01f9c55eda66' && ['creator','agencyOwner','admin'].includes(userType)){
      return 'Australia,Canada,UK'
    }else{
      return campaign?.desiredRegions[0]?.value
    }
  }
