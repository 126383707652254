import React, { useEffect, useState } from 'react'

import { Image } from 'primereact/image';
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import noImage from "@/react_app/img/no-image.png";
import CustomLoader from '@/react_app/Other/CustomLoader/CustomLoader';
import { ExpandArrowDown, ExpandArrowUp, AndroidIcon, AppleIcon } from '@/react_app/img/Icons';

import { useAuth } from '@/modules';
import { CampaignPaymentMethod } from '@/data/types';
import { calculateCommission } from '@/utils/globals/numbers-utils';
import { useCreatorManager } from '@/modules/creators/creator-manager-module';

import "./NewInfluencerEarnings.css";

const creatorTemplate = (data) => {
  return (
    <div className="creatorTemplate">
      <Image src={data.photo || noImage} alt="creator photo" className="creatorPhoto"></Image>
      <p className="creatorName">{data.name}</p>
    </div>
  )
};
const creatorEmailTemplate = (data) => (
  <div className="p-d-flex creatorName">
    <span className="link" onClick={() => window.location = `mailto:${data?.email}`}>{data?.email || "-"}</span>
  </div>
);
const creatorTypeTemplate = (data) => (
  <div className="p-d-flex creatorName">
    <span className="capital">{data?.userType || "-"}</span>
  </div>
);
const totalEarningsTemplate = (data) => (
  <div className="defaultTemplate">
    $ {data.totalEarnings?.toFixed(2) || 0}
  </div>
);
const totalPaidTemplate = (data) => (
  <div className="defaultTemplate">
    $ {data.totalPaid?.toFixed(2) || 0}
  </div>
);
const eligibleForPayoutTemplate = (data) => (
  <div className="defaultTemplate">
    $ {data.amountEarnedThisCycle?.toFixed(2) || 0}
  </div>
);
const gigaPayTemplate = (data) => (
  <div className="defaultTemplate">
    {data.gigapayStatus ? 'Yes' : 'No'}
  </div>
);

const nameTemplate = (row) => (
  <div className="p-d-flex creatorTemplate">
    <Image src={row.campaignPhoto} alt="creator photo" className="creatorPhoto"></Image>
    <span className='creatorName'>{row?.campaignName}</span>
  </div>
)
const campaignTypeTemplate = (row) => (
  <div className="p-d-flex contentTitle">
    {row?.campaignPaymentMethod || "-"}
  </div>
);
const campaignCommissionRateTemplate = (row) => (
  <div className="amount">
    <span>{row?.appliedFee || 0} USD</span>
  </div>
);
const campaignPaymentAmountTemplate = ({ campaignPaymentMethod, cpc, cpm, cpi }) => {
  const paymentMethods = {
    [CampaignPaymentMethod.CPC]: () => (
      <span className="coverValue">{`${cpc.amount} ${cpc.currency}`}</span>
    ),
    [CampaignPaymentMethod.CPM]: () => (
      <span className="coverValue">{`${cpm.amount} ${cpm.currency}`}</span>
    ),
    [CampaignPaymentMethod.CPI]: () => (
      <>
        <div className="coverValue">
          <AndroidIcon />
          {`${cpi.android.amount} ${cpi.android.currency}`}
        </div>
        <div className="coverValue">
          <AppleIcon />
          {`${cpi.ios.amount} ${cpi.ios.currency}`}
        </div>
      </>
    )
  };
  return paymentMethods[campaignPaymentMethod] ? paymentMethods[campaignPaymentMethod]() : null;
};
const campaignEarnedTemplate = (row, isWithCommission) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="amount">{isWithCommission ? row?.earned?.toFixed(2) : calculateCommission(row?.earned, row?.appliedFee)} USD</span>
    <div className="p-d-flex p-flex-column">
      <span>Android: {row?.earnedStats?.android || 0}</span>
      <span>IOS: {row?.earnedStats?.ios}</span>
      <span>Clicks: {row?.earnedStats?.clicks}</span>
    </div>
  </div>
);
const campaignPaidTemplate = (row, isWithCommission) => (
  <div className="p-d-flex p-flex-column defaultTemplate">
    <span className="contentTitle">{isWithCommission ? row?.paid?.toFixed(2) : calculateCommission(row?.paid, row?.appliedFee)} USD</span>
    <div className="p-d-flex p-flex-column">
      <span>Android: {row?.paidStats?.android || 0}</span>
      <span>IOS: {row?.paidStats?.ios || 0}</span>
      <span>Clicks: {row?.paidStats?.clicks || 0}</span>
    </div>
  </div>
);


export default function NewInfluencerEarnings() {

  const user = useAuth();
  const manager = useCreatorManager();
  const [loader, setLoader] = useState(true);
  const [earningList, setEarningList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const getInfluencerEarnings = () => {
    manager.getInfluencerEarning(10, 0, user.user.value.id).then(response => {
      if (response) {
        const earnings = response.creatorEarnings || [];
        setEarningList(earnings);
        setExpandedRows(earnings); // Set all rows to expanded by default
      }
      setLoader(false);
    });

  };
  useEffect(() => {
    getInfluencerEarnings();
  }, [])

  const expanderTemplate = (rowData) => (
    <div className='pointer'>
      {expandedRows.includes(rowData) ?
        <div onClick={() => setExpandedRows(prevState => prevState.filter(item => item !== rowData))}>
          <ExpandArrowUp />
        </div> :
        <div onClick={() => setExpandedRows(prevState => [...prevState, rowData])}>
          <ExpandArrowDown />
        </div>
      }
    </div>
  );
  const rowExpansionTemplate = (rowData) => (
    <DataTable value={rowData?.links} className="w-100">
      <Column field="campaignName" header="Campaign" body={nameTemplate} style={{ width: '20%' }} />
      <Column field="campaignPaymentMethod" header="Payment Method" style={{ width: '10%' }} body={campaignTypeTemplate} />
      <Column field="campaignPayment" header="Rate" body={campaignPaymentAmountTemplate} />
      <Column field="appliedFee" header="Commission Rate" body={campaignCommissionRateTemplate} />
      <Column field="earned" header="Earned (With Commission)" body={(data) => campaignEarnedTemplate(data, true)} />
      <Column field="earned" header="Earned (Without Commission)" body={(data) => campaignEarnedTemplate(data, false)} />
      <Column field="paid" header="Paid (With Commission)" body={(data) => campaignPaidTemplate(data, true)} />
      <Column field="paid" header="Paid  (Without Commission)" body={(data) => campaignPaidTemplate(data, false)} />
    </DataTable>
  );

  return (
    <div className="influencer-earning">
      <DataTable value={earningList} emptyMessage="No influencer earning found." loading={loader} expandedRows={expandedRows}
        loadingIcon={<CustomLoader />} style={{ minHeight: 500 }} rowExpansionTemplate={rowExpansionTemplate}>
        <Column expander={true} body={expanderTemplate} style={{ width: '5%' }} />
        <Column field="name" header="Influencer" body={creatorTemplate} style={{ width: '20%' }} />
        <Column field="userType" header="Influencer Type" body={creatorTypeTemplate} style={{ width: '10%' }} />
        <Column field="email" header="Email" body={creatorEmailTemplate} style={{ width: '15%' }} />
        <Column field="totalEarnings" header="Total Earnings" body={totalEarningsTemplate} />
        <Column field="totalPaid" header="Paid Out" body={totalPaidTemplate} />
        <Column field="amountEarnedThisCycle" header="Eligible for Payout" body={eligibleForPayoutTemplate} />
        <Column field="gigapayStatus" header="On Gigapay" body={gigaPayTemplate} />
      </DataTable>
    </div>
  )
}